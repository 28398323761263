import React, { useEffect, useState } from "react";
import { useForm } from "hooks/useForm";
import CustomInput from "components/CustomInput";
import { GridContainer, StyledFlex } from "constants/commonStyles";
import FormLayout from "layouts/FormLayout";
import MainLayout from "layouts/MainLayout";
import { FieldTypes } from "constants/fieldTypes";
import CustomRadio from "components/CustomRadio";
import { STRING_CONSTANTS, ROUTE_CONSTANTS } from "constants/common";
import CustomFileInput from "components/CustomFileInput";
import CustomDatePicker from "components/CustomDatePicker";
import { getLanguages } from "constants/utilities";
import { initializeBannerSchema } from "./AddEditBannerUtils";
import useDocumentTitle from "hooks/useDocumentTitle";
import "./index.css";
import CustomConfirmModal from "components/CustomConfirmModal";
import { useNavigate } from "react-router-dom";

const BannerManagementForm = ({ mode, formHeading, isView }) => {
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [initialReqData, setInitialReqData] = useState({});
  const languages = getLanguages(STRING_CONSTANTS.COMMON.LANGUAGES_LIST);
  const { webSchema, mobileSchema } = initializeBannerSchema(languages);
  const PageTitle =
    mode === STRING_CONSTANTS.COMMON.MODE.ADD
      ? STRING_CONSTANTS.BANNER_MASTER.ADD_BANNER_FORM_HEADING
      : STRING_CONSTANTS.BANNER_MASTER.EDIT_BANNER_FORM_HEADING;
  useDocumentTitle(`${STRING_CONSTANTS.COMMON.EDULAC_TITLE}-${PageTitle}`);
  const navigate = useNavigate();

  const initialState = {
    bannertitle: {
      value: "",
      error: "",
      required: true,
    },
    textOnBanner: {
      value: "",
      error: "",
      required: true,
    },
    ctaButtonLabel: {
      value: "",
      error: "",
      required: true,
    },
    redirectionUrl: {
      value: "",
      error: "",
      required: true,
    },
    sequence: {
      value: "",
      error: "",
      required: true,
    },
    position: {
      value: "",
      error: "",
    },
    duration: {
      value: "",
      error: "",
    },
    isDefaultBanner: {
      value: "",
      error: "",
    },
    startDate: {
      value: "",
      error: "",
      required: true,
    },
    endDate: {
      value: "",
      error: "",
      required: true,
    },
    ...mobileSchema,
    ...webSchema,
  };

  const handleBannereMasterSubmission = (params, error) => {
    if (mode) {
    } else {
    }
  };

  const {
    values,
    errors,
    dirty,
    handleOnSubmit,
    handleOnChange,
    clearStateSchema,
  } = useForm(initialState, handleBannereMasterSubmission);

  useEffect(() => {
    setInitialReqData(values);
  }, [values]);

  const callUploadApi = async (fileData) => {
    const fileSelected = fileData;
    let size = parseFloat(fileSelected?.size / (1024 * 1024)).toFixed(2);
    if (!fileSelected.name.match(/\.(doc|docx|pdf)$/)) {
      return;
    }
  };

  const dateCommonProps = {
    required: true,
    hasIcon: true,
    onChangeHandler: handleOnChange,
    className: "banner-date",
    disableAutoFocus: true,
  };

  const commonInputProps = {
    type: FieldTypes?.TYPE_TEXT,
    onChangeHandler: handleOnChange,
    required: true,
    disableAutoFocus: true,
  };

  const commonCheckboxProps = {
    classes: "position-banner",
    required: true,
  };

  const onCancelClickHandler = () => {
    const formDirty = Object.keys(dirty)?.some((key) => dirty[key]);
    if (
      !formDirty &&
      JSON.stringify(initialReqData) === JSON.stringify(values)
    ) {
      clearStateSchema();
      navigate(ROUTE_CONSTANTS.BANNER_MANAGEMENT);
    } else {
      setShowConfirmationPopup(true);
    }
  };

  return (
    <MainLayout>
      <FormLayout
        formHeading={formHeading}
        handleOnSubmit={handleOnSubmit}
        cancelBtnText={"Cancel"}
        cancelBtnRoute={ROUTE_CONSTANTS.BANNER_MANAGEMENT}
        saveBtnText={"Save"}
        isView={isView}
        viewButtonText={"Edit"}
        viewFormEditRoute={ROUTE_CONSTANTS.EDIT_BANNER}
        onCancel={onCancelClickHandler}
      >
        <StyledFlex>
          <CustomInput
            name="bannertitle"
            placeholder="Banner Title"
            label="Banner Title"
            value={values?.bannertitle}
            hasError={errors?.bannertitle && dirty?.bannertitle}
            errorMessage={errors?.bannertitle}
            minLength={2}
            {...commonInputProps}
          />
        </StyledFlex>
        <StyledFlex>
          <CustomInput
            name="textOnBanner"
            placeholder="Text On Banner"
            label="Text On Banner"
            value={values?.textOnBanner}
            hasError={errors?.textOnBanner && dirty?.textOnBanner}
            errorMessage={errors?.textOnBanner}
            {...commonInputProps}
          />
          <CustomInput
            name="ctaButtonLabel"
            placeholder="Cta Button Label"
            label="Cta Button Label"
            value={values?.ctaButtonLabel}
            hasError={errors?.ctaButtonLabel && dirty?.ctaButtonLabel}
            errorMessage={errors?.ctaButtonLabel}
            {...commonInputProps}
          />
        </StyledFlex>
        <StyledFlex>
          <CustomInput
            name="redirectionUrl"
            placeholder="Redirection Url"
            label="Redirection Url"
            value={values?.redirectionUrl}
            hasError={errors?.redirectionUrl && dirty?.redirectionUrl}
            errorMessage={errors?.redirectionUrl}
            {...commonInputProps}
          />
          <CustomInput
            name="sequence"
            placeholder="Sequence"
            label="Sequence"
            value={values?.sequence}
            hasError={errors?.sequence && dirty?.sequence}
            errorMessage={errors?.sequence}
            {...commonInputProps}
          />
        </StyledFlex>
        <GridContainer>
          <CustomRadio
            fieldName="position"
            options={STRING_CONSTANTS.BANNER_MASTER.LIST_BANNER_POSITION}
            checked={values?.position}
            title={"Position"}
            {...commonCheckboxProps}
          />
          <CustomRadio
            fieldName="isDefaultBanner"
            options={STRING_CONSTANTS.BANNER_MASTER.LIST_DEFAULT_BANNER_TYPE}
            checked={values?.isDefaultBanner}
            title={"Choose Default Banner"}
            {...commonCheckboxProps}
          />
        </GridContainer>
        <div className="banner-image-content">
          {languages?.length > 0
            ? languages?.map((lang) => {
                const fieldName = `bannerImageWebsite_${lang?.languageCode}`;
                return (
                  <GridContainer marginTop="14" key={lang?.languageCode}>
                    <CustomFileInput
                      accepts="image/png, image/jpeg, image/jpg"
                      fieldName={fieldName}
                      title={`Banner Website In ${lang?.name}`}
                      description={`Upload a banner image for the website in ${lang?.name}`}
                      onChangeHandler={callUploadApi}
                      hasError={errors?.[fieldName] && dirty?.[fieldName]}
                      errorMessage={errors?.[fieldName]}
                      hasPreview={values[fieldName]?.value}
                      preview={values[fieldName]?.value}
                      isDragAndDropRequired={true}
                      disableAutoFocus={true}
                      required={true}
                    />
                  </GridContainer>
                );
              })
            : null}
        </div>
        <div className="banner-image-content">
          {languages?.length > 0
            ? languages?.map((lang) => {
                const fieldName = `bannerImageMobile_${lang?.languageCode}`;
                return (
                  <GridContainer marginTop="14" key={lang?.languageCode}>
                    <CustomFileInput
                      accepts="image/png, image/jpeg, image/jpg"
                      fieldName={fieldName}
                      title={`Banner Mobile In ${lang?.name}`}
                      description={`Upload a banner image for the mobile in ${lang?.name}`}
                      onChangeHandler={callUploadApi}
                      hasError={errors?.[fieldName] && dirty?.[fieldName]}
                      errorMessage={errors?.[fieldName]}
                      hasPreview={values[fieldName]?.value}
                      preview={values[fieldName]?.value}
                      isDragAndDropRequired={true}
                      disableAutoFocus={true}
                      required={true}
                    />
                  </GridContainer>
                );
              })
            : null}
        </div>

        <GridContainer marginTop={"10"}>
          <CustomDatePicker
            fieldName={"startDate"}
            title={"Start Date"}
            data={values?.startDate}
            placeholder={values?.startDate}
            hasError={errors?.startDate && dirty?.startDate}
            errorMessage={errors?.startDate}
            {...dateCommonProps}
          />
          <CustomDatePicker
            fieldName={"endDate"}
            title={"End Date"}
            data={values?.endDate}
            placeholder={values?.endDate}
            hasError={errors?.endDate && dirty?.endDate}
            errorMessage={errors?.endDate}
            {...dateCommonProps}
          />
        </GridContainer>
      </FormLayout>
      <CustomConfirmModal
        show={showConfirmationPopup}
        title={STRING_CONSTANTS.COMMON.TITLE_CONFIRMATION_BACK_MESSAGE}
        onCancel={() => setShowConfirmationPopup(false)}
        handleOnConfirm={() => navigate(ROUTE_CONSTANTS.BANNER_MANAGEMENT)}
      />
    </MainLayout>
  );
};

export default BannerManagementForm;
