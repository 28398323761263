import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { REDUX_KEYS_ACTIONS } from "constants/common";

export const gradeMasterAdapter = createEntityAdapter();

export const fetchGradeMasterList = createAsyncThunk(
  REDUX_KEYS_ACTIONS.GRADE_MASTER.FETCH_GRADE_MASTER_LIST,
  async () => {
    const response = await fetch("");
    return response?.json();
  }
);

export const initialGradeMasterState = {
  gradeMasterReduxData: {
    loadingStatus: "not loaded",
    error: null,
    isLoading: false,
  },
};

export const gradeMasterSlice = createSlice({
  name: REDUX_KEYS_ACTIONS.GRADE_MASTER.FEATURE_KEY,
  initialState: initialGradeMasterState,
  reducers: {
    add: gradeMasterAdapter.addOne,
    remove: gradeMasterAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGradeMasterList.pending, (state) => {
      state.gradeMasterReduxData.isLoading = true;
      state.gradeMasterReduxData.loadingStatus = "loading";
    });
  },
});

export const gradeMasterReducer = gradeMasterSlice.reducer;

export const gradeMasterActions = gradeMasterSlice.actions;

export const getGradeMasterState = (rootState) =>
  rootState[REDUX_KEYS_ACTIONS.GRADE_MASTER.FEATURE_KEY];
