import React, { useState, useEffect } from "react";
import FormLayout from "layouts/FormLayout";
import MainLayout from "layouts/MainLayout";
import { useForm } from "hooks/useForm";
import CustomDropdown from "components/CustomDropdown";
import CustomInput from "components/CustomInput";
import CustomTextarea from "components/CustomTextarea";
import { StyledFlex } from "constants/commonStyles";
import { STRING_CONSTANTS, ROUTE_CONSTANTS } from "constants/common";
import { FieldTypes } from "constants/fieldTypes";
import { useNavigate } from "react-router-dom";
import useDocumentTitle from "hooks/useDocumentTitle";
import "./index.css";
import CustomConfirmModal from "components/CustomConfirmModal";

const SchoolFacilitiesForm = (props) => {
  const { formHeading, mode } = props;
  const [initialReqData, setInitialReqData] = useState({});
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const navigate = useNavigate();
  const PageTitle =
    mode === STRING_CONSTANTS.COMMON.MODE.ADD
      ? STRING_CONSTANTS.SCHOOL_FACILITY_MASTER.ADD_SCHOOL_FACILITIES
      : mode === STRING_CONSTANTS.COMMON.MODE.VIEW
      ? STRING_CONSTANTS.SCHOOL_FACILITY_MASTER.VIEW_SCHOOL_FACILITIES
      : STRING_CONSTANTS.SCHOOL_FACILITY_MASTER.EDIT_SCHOOL_FACILITIES;
  useDocumentTitle(`${STRING_CONSTANTS.COMMON.EDULAC_TITLE}-${PageTitle}`);

  const initialState = {
    facilityNameInEnglish: {
      value: "",
      error: "",
      required: true,
    },
    facilityNameInFrench: {
      value: "",
      error: "",
      required: true,
    },
    descriptionInEnglish: {
      value: "",
      error: "",
      required: true,
    },
    descriptionInFrench: {
      value: "",
      error: "",
      required: true,
    },
    schoolCategory: {
      value: "",
      error: "",
      required: true,
    },
    schoolStatus: {
      value: "",
      error: "",
      required: true,
    },
    type: {
      value: "",
      error: "",
      required: true,
    },
  };

  const handleGradeMasterSubmission = (params, error) => {
    if (mode) {
    } else {
    }
  };

  const {
    errors,
    dirty,
    values,
    handleOnSubmit,
    handleOnChange,
    handleOnSelection,
    clearStateSchema,
  } = useForm(initialState, handleGradeMasterSubmission);

  useEffect(() => {
    setInitialReqData(values);
  }, [values]);

  const options = [
    { value: "option1", label: "Option 1" },
    { value: "option2", label: "Option 2" },
    { value: "option3", label: "Option 3" },
  ];

  const commonInputProps = {
    type: FieldTypes.TYPE_TEXT,
    placeholder: "Add Facility Name",
    onChangeHandler: handleOnChange,
    mode,
    required: true,
  };

  const commonTextAreaProps = {
    type: FieldTypes.TYPE_TEXT,
    placeholder: "Add Description.",
    onChangeHandler: handleOnChange,
    mode,
    rows: "2",
    cols: "2",
    required: true,
    textareaClass: "school-faciliites-textarea-content",
  };

  const commonDropDownProps = {
    options,
    placeholder: "Select",
    onChangeHandler: handleOnSelection,
    allowClear: true,
    mode,
    required: true,
  };

  const onCancelClickHandler = () => {
    const formDirty = Object.keys(dirty)?.some((key) => dirty[key]);
    if (
      !formDirty &&
      JSON.stringify(initialReqData) === JSON.stringify(values)
    ) {
      clearStateSchema();
      navigate(ROUTE_CONSTANTS.SCHOOL_FACILITIES_MASTER);
    } else {
      setShowConfirmationPopup(true);
    }
  };

  return (
    <MainLayout>
      <FormLayout
        formHeading={formHeading}
        handleOnSubmit={handleOnSubmit}
        cancelBtnText={STRING_CONSTANTS.COMMON.BUTTON.CANCEL_TITLE}
        cancelBtnRoute={ROUTE_CONSTANTS.SCHOOL_FACILITIES_MASTER}
        saveBtnText={STRING_CONSTANTS.COMMON.BUTTON.SAVE_TITLE}
        mode={mode}
        viewButtonText={STRING_CONSTANTS.COMMON.BUTTON.EDIT_TITLE}
        viewFormEditRoute={ROUTE_CONSTANTS.EDIT_SCHOOL_FACILITIES}
        onCancel={onCancelClickHandler}
      >
        <StyledFlex>
          <CustomInput
            name="facilityNameInEnglish"
            label={"Add Facility Name" + STRING_CONSTANTS.COMMON.ENGLISH_LABEL}
            value={values?.facilityNameInEnglish}
            hasError={
              errors?.facilityNameInEnglish && dirty?.facilityNameInEnglish
            }
            errorMessage={errors?.facilityNameInEnglish}
            {...commonInputProps}
          />
          <CustomInput
            name="facilityNameInFrench"
            label={"Add Facility Name" + STRING_CONSTANTS.COMMON.FRENCH_LABEL}
            value={values?.facilityNameInFrench}
            hasError={
              errors?.facilityNameInFrench && dirty?.facilityNameInFrench
            }
            errorMessage={errors?.facilityNameInFrench}
            {...commonInputProps}
          />
        </StyledFlex>
        <StyledFlex>
          <CustomTextarea
            name="descriptionInEnglish"
            label={"Add Description." + STRING_CONSTANTS.COMMON.ENGLISH_LABEL}
            value={values?.descriptionInEnglish}
            hasError={
              errors?.descriptionInEnglish && dirty?.descriptionInEnglish
            }
            errorMessage={errors?.descriptionInEnglish}
            {...commonTextAreaProps}
          />
          <CustomTextarea
            name="descriptionInFrench"
            label={"Add Description." + STRING_CONSTANTS.COMMON.FRENCH_LABEL}
            value={values?.descriptionInFrench}
            hasError={errors?.descriptionInFrench && dirty?.descriptionInFrench}
            errorMessage={errors?.descriptionInFrench}
            {...commonTextAreaProps}
          />
        </StyledFlex>
        <StyledFlex>
          <CustomDropdown
            fieldName={"schoolCategory"}
            label={"Select School Category"}
            placeholder="Select School Category"
            value={values?.schoolCategory}
            hasError={errors?.schoolCategory && dirty?.schoolCategory}
            errorMessage={errors?.schoolCategory}
            {...commonDropDownProps}
          />
          <CustomDropdown
            fieldName={"schoolStatus"}
            label={"Select School Status"}
            placeholder="Select School Status"
            value={values?.schoolStatus}
            hasError={errors?.schoolStatus && dirty?.schoolStatus}
            errorMessage={errors?.schoolStatus}
            {...commonDropDownProps}
          />
        </StyledFlex>
        <StyledFlex>
          <CustomDropdown
            fieldName={"type"}
            label={"Select School Education Type"}
            placeholder="Select School Education Type"
            value={values?.type}
            hasError={errors?.type && dirty?.type}
            errorMessage={errors?.type}
            {...commonDropDownProps}
          />
        </StyledFlex>
      </FormLayout>
      <CustomConfirmModal
        show={showConfirmationPopup}
        title={STRING_CONSTANTS.COMMON.TITLE_CONFIRMATION_BACK_MESSAGE}
        onCancel={() => setShowConfirmationPopup(false)}
        handleOnConfirm={() =>
          navigate(ROUTE_CONSTANTS.SCHOOL_FACILITIES_MASTER)
        }
      />
    </MainLayout>
  );
};

export default SchoolFacilitiesForm;
