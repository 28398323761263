import React from "react";
import { useForm } from "hooks/useForm";
import { GridContainer, StyledFlex } from "constants/commonStyles";
import { ROUTE_CONSTANTS, STRING_CONSTANTS } from "constants/common";
import useDocumentTitle from "hooks/useDocumentTitle";
import FormLayout from "layouts/FormLayout";
import MainLayout from "layouts/MainLayout";
import CustomInput from "components/CustomInput";
import CustomDropdown from "components/CustomDropdown";
import { FieldTypes } from "constants/fieldTypes";
import CustomFileInput from "components/CustomFileInput";
import "./index.css";

const RequestManagementForm = (props) => {
  const { mode, formHeading } = props;
  const PageTitle =
    mode === STRING_CONSTANTS.COMMON.MODE.VIEW
      ? STRING_CONSTANTS.REQUEST_MANAGEMENT_MASTER
          .HEADER_TITLE_VIEW_REQUEST_MANAGEMENT
      : STRING_CONSTANTS.BANNER_MASTER.HEADER_TITLE_LIST_REQUEST_MANAGEMENT;
  useDocumentTitle(`${STRING_CONSTANTS.COMMON.EDULAC_TITLE}-${PageTitle}`);

  const initialState = {
    ministerType: {
      value: "MENAPLN",
      error: "",
    },
    ministerName: {
      value: "Minister Name",
      error: "",
    },
    schoolCategory: {
      value: "Primary",
      error: "",
    },
    emailId: {
      value: "demo@gmail.com",
      error: "",
    },
    contactNumber: {
      value: "7809878909",
      error: "",
    },
    idCardPhoto: {
      value: "",
      error: "",
    },
    passportPhoto: {
      value: "",
      error: "",
    },
    drivingLicencePhoto: {
      value: "",
      error: "",
    },
  };

  const onHandledRequestManagement = (params, error) => {
    if (mode) {
    } else {
    }
  };

  const { values, handleOnSubmit, handleOnChange, handleOnSelection } = useForm(
    initialState,
    onHandledRequestManagement
  );

  const options = [
    { value: "option1", label: "Option 1" },
    { value: "option2", label: "Option 2" },
    { value: "option3", label: "Option 3" },
  ];

  const commonInputProps = {
    onChangeHandler: handleOnChange,
    mode: mode,
    required: true,
    type: FieldTypes.TYPE_TEXT,
  };

  const commonDropDownProps = {
    placeholder: "Select",
    options: options,
    onChangeHandler: handleOnSelection,
    allowClear: true,
    mode: mode,
    required: true,
  };

  const callUploadApi = async (fileData) => {
    const fileSelected = fileData;
    let size = parseFloat(fileSelected?.size / (1024 * 1024)).toFixed(2);
    if (!fileSelected.name.match(/\.(doc|docx|pdf)$/)) {
      return;
    }
  };

  const commonFileInputProps = {
    accepts: "image/png, image/jpeg, image/jpg",
    onChangeHandler: callUploadApi,
    isDragAndDropRequired: true,
    disableAutoFocus: true,
    required: true,
  };

  return (
    <MainLayout>
      <FormLayout
        formHeading={formHeading}
        handleOnSubmit={handleOnSubmit}
        cancelBtnText={STRING_CONSTANTS.COMMON.BUTTON.CANCEL_TITLE}
        cancelBtnRoute={ROUTE_CONSTANTS.REQUEST_MANAGEMENT}
        saveBtnText={STRING_CONSTANTS.COMMON.BUTTON.SAVE_TITLE}
        viewButtonText={STRING_CONSTANTS.COMMON.BUTTON.EDIT_TITLE}
        viewFormEditRoute={ROUTE_CONSTANTS.VIEW_REQUEST_MANAGEMENT}
      >
        <StyledFlex>
          <CustomInput
            name="ministerName"
            placeholder="Minister Name"
            label="Minister Name"
            value={values?.ministerName}
            {...commonInputProps}
          />
          <CustomInput
            name="emailId"
            placeholder="Email Id"
            label="Email Id"
            value={values?.emailId}
            {...commonInputProps}
          />
        </StyledFlex>
        <StyledFlex>
          <CustomInput
            name="contactNumber"
            placeholder="Contact Number"
            label="Contact Number"
            value={values?.contactNumber}
            {...commonInputProps}
          />
          <CustomDropdown
            fieldName={"ministerType"}
            label={"Minister Type"}
            value={values?.ministerType}
            placeholder="Select Minister Type"
            classes={"request-mgmt-minister-type"}
            {...commonDropDownProps}
          />
        </StyledFlex>
        <StyledFlex>
          <CustomDropdown
            fieldName={"schoolCategory"}
            label={"School Category"}
            value={values?.schoolCategory}
            placeholder="Select School Category"
            classes="request-mgmt-school-category"
            {...commonDropDownProps}
          />
        </StyledFlex>
        <GridContainer marginTop="14">
          <CustomFileInput
            fieldName={"idCardPhoto"}
            title={"Id Card Photo"}
            description={"Upload An Id Card Image"}
            hasPreview={values?.idCardPhoto}
            preview={values?.idCardPhoto}
            {...commonFileInputProps}
          />
          <CustomFileInput
            fieldName={"passportPhoto"}
            title={"Passport Photo"}
            description={"Upload An Passport Photo Image"}
            hasPreview={values?.passportPhoto}
            preview={values?.passportPhoto}
            {...commonFileInputProps}
          />
        </GridContainer>
        <GridContainer marginTop="14">
          <CustomFileInput
            fieldName={"drivingLicencePhoto"}
            title={"Driving Licence Photo"}
            description={"Upload An Driving Licence Photo Image"}
            hasPreview={values?.drivingLicencePhoto}
            preview={values?.drivingLicencePhoto}
            className="request-mgmt-driving-photo"
            {...commonFileInputProps}
          />
        </GridContainer>
      </FormLayout>
    </MainLayout>
  );
};

export default RequestManagementForm;
