import React, { useState, Suspense, lazy } from "react";
import { useNavigate } from "react-router-dom";
import { CustomTable } from "components/CustomTable";
import CustomModal from "components/CustomModal";
import RenderTableActionsButton from "components/RenderTableActionsButton";
import {
  STRING_CONSTANTS,
  ROUTE_CONSTANTS,
  STATIC_VALUES,
  REDUX_KEYS_ACTIONS,
} from "constants/common";
import "./index.css";
import { useSelector } from "react-redux";

const MainLayout = lazy(() => import("layouts/MainLayout"));
const RenderNewsManagementFilters = lazy(() =>
  import("./RenderNewsManagementFilters")
);

const NewsManagement = () => {
  const { newsManagementReduxData } = useSelector(
    (state) => state[REDUX_KEYS_ACTIONS.NEWS_MANAGEMENT.FEATURE_KEY]
  );
  const [isFilter, setIsFilter] = useState(false);
  const [switchChecked, setSwitchChecked] = useState(false);
  const defaultModalProps = { isOpen: false, data: null };
  const [modal, setModal] = useState(defaultModalProps);
  const navigate = useNavigate();

  const columns = [
    {
      field: "id",
      header: STATIC_VALUES.COLUMNS.COMMON.ID,
    },
    {
      field: "title",
      header: STATIC_VALUES.COLUMNS.COMMON.TITLE,
      enableSort: true,
    },
    {
      field: "thumbNailImage",
      header: STATIC_VALUES.COLUMNS.COMMON.THUMBNAILIMAGE,
      enableSort: true,
    },
    {
      field: "newsStatus",
      header: STATIC_VALUES.COLUMNS.NEWS_MANAGEMENT.NEWS_STATUS,
      enableSort: true,
    },
    {
      field: "redirectionLink",
      header: STATIC_VALUES.COLUMNS.NEWS_MANAGEMENT.NEWS_REDIRECTION_LINK,
      enableSort: true,
    },
    {
      field: "createdAt",
      header: STATIC_VALUES.COLUMNS.COMMON.CREATED_AT,
      enableSort: true,
    },
    {
      field: "updatedAt",
      header: STATIC_VALUES.COLUMNS.COMMON.UPDATED_AT,
      enableSort: true,
    },
    {
      field: "createdBy",
      header: STATIC_VALUES.COLUMNS.COMMON.CREATED_BY,
      enableSort: true,
    },
    {
      field: "updatedBy",
      header: STATIC_VALUES.COLUMNS.COMMON.UPDATED_BY,
      enableSort: true,
    },
    {
      field: "action",
      header: STATIC_VALUES.COLUMNS.COMMON.ACTIONS,
      shouldRenderElement: true,
      renderElement: (value, index, data) => {
        return (
          <RenderTableActionsButton
            setModal={setModal}
            switchChecked={switchChecked}
            handleOnSwitch={handleOnSwitch}
            handleDeleteClick={() => setModal({ data, isOpen: true })}
            showViewIcon={true}
            showDeleteIcon={true}
            showEditIcon={true}
            handleEditRoute={ROUTE_CONSTANTS.EDIT_NEWS}
            handleViewRoute={ROUTE_CONSTANTS.VIEW_NEWS}
          />
        );
      },
    },
  ];

  const data = [
    {
      id: "1",
      title: "Ratan Tata Dies at 86",
      thumbNailImage: "-",
      newsStatus: "Active",
      redirectionLink: "https://www.google.com",
      createdAt: "10-10-2024",
      updatedAt: "10-10-2024",
      createdBy: "Microsoft",
      updatedBy: "Microsoft",
    },
    {
      id: "2",
      title: "Global News",
      thumbNailImage: "-",
      newsStatus: "Active",
      redirectionLink: "https://www.google.com",
      createdAt: "10-10-2024",
      updatedAt: "10-10-2024",
      createdBy: "Microsoft",
      updatedBy: "Microsoft",
    },
    {
      id: "3",
      title: "Commercial News",
      thumbNailImage: "-",
      newsStatus: "Active",
      redirectionLink: "https://www.google.com",
      createdAt: "10-10-2024",
      updatedAt: "10-10-2024",
      createdBy: "Microsoft",
      updatedBy: "Microsoft",
    },
    {
      id: "4",
      title: "Sports News",
      thumbNailImage: "-",
      newsStatus: "Active",
      redirectionLink: "https://www.google.com",
      createdAt: "10-10-2024",
      updatedAt: "10-10-2024",
      createdBy: "Accenture",
      updatedBy: "Accenture",
    },
    {
      id: "5",
      title: "Health News",
      thumbNailImage: "-",
      newsStatus: "Active",
      redirectionLink: "https://www.google.com",
      createdAt: "10-10-2024",
      updatedAt: "10-10-2024",
      createdBy: "FaceBook",
      updatedBy: "FaceBook",
    },
    {
      id: "6",
      title: "Politics News",
      thumbNailImage: "-",
      newsStatus: "Inactive",
      redirectionLink: "https://www.google.com",
      createdAt: "10-10-2024",
      updatedAt: "10-10-2024",
      createdBy: "Microsoft",
      updatedBy: "Microsoft",
    },
    {
      id: "7",
      title: "Ratan Tata Dies at 86",
      thumbNailImage: "-",
      newsStatus: "Inactive",
      redirectionLink: "https://www.google.com",
      createdAt: "10-10-2024",
      updatedAt: "10-10-2024",
      createdBy: "Microsoft",
      updatedBy: "Microsoft",
    },
    {
      id: "8",
      title: "Ratan Tata Dies at 86",
      thumbNailImage: "-",
      newsStatus: "Inactive",
      redirectionLink: "https://www.google.com",
      createdAt: "10-10-2024",
      updatedAt: "10-10-2024",
      createdBy: "Microsoft",
      updatedBy: "Microsoft",
    },
  ];

  const onChangeData = () => {};

  const multiSelectOptions = [
    { label: "Option 1", value: "option1" },
    { label: "Option 2", value: "option2" },
    { label: "Option 3", value: "option3" },
  ];

  const handleIsFilter = () => {
    setIsFilter(!isFilter);
  };

  const handleOnSwitch = (e, key) => {
    setSwitchChecked(() => e?.target?.checked);
  };

  const handleAddRoute = (params, error) => {
    navigate(ROUTE_CONSTANTS.ADD_NEWS);
  };

  return (
    <Suspense>
      <MainLayout>
        {modal?.isOpen ? (
          <CustomModal
            modal={modal?.isOpen}
            setModal={() => setModal(defaultModalProps)}
            modalTitle={"Delete Modal"}
            modalInnerContent={STRING_CONSTANTS.COMMON.RECORD_DELETE_STRING}
            footerBtnText={"Yes"}
          />
        ) : null}
        <CustomTable
          showAddButton={true}
          handleAddRoute={handleAddRoute}
          isFilter={isFilter}
          addBtnText={"Add"}
          tableHeader={
            STRING_CONSTANTS.NEWS_MANAGEMENT.HEADER_TITLE_NEWS_MANAGEMENT
          }
          filterOptions={
            <Suspense>
              <RenderNewsManagementFilters
                showDateFilter={true}
                showMultiSelectFilter={true}
                multiSelectOptions={multiSelectOptions}
              />
            </Suspense>
          }
          handleIsFilter={handleIsFilter}
          data={data}
          columns={columns}
          count={10}
          width={"100"}
          noDataFoundMessage={
            STRING_CONSTANTS.NEWS_MANAGEMENT.NO_DATA_FOR_NEWS_MANAGEMENT_FOUND
          }
          class={"table_class"}
          onChangeData={onChangeData}
          btnMarginTop={false}
        />
        <CustomModal />
      </MainLayout>
    </Suspense>
  );
};

export default NewsManagement;
