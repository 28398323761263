import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { REDUX_KEYS_ACTIONS } from "constants/common";

export const newsManagementAdapter = createEntityAdapter();

export const fetchNewsList = createAsyncThunk(
  REDUX_KEYS_ACTIONS.NEWS_MANAGEMENT.FETCH_NEWS_LIST,
  async () => {
    const response = await fetch("");
    return response?.json();
  }
);

export const initialNewsManagementState = {
  newsManagementReduxData: {
    loadingStatus: "not loaded",
    error: null,
    isLoading: false,
  },
};

export const newsManagementSlice = createSlice({
  name: REDUX_KEYS_ACTIONS.NEWS_MANAGEMENT.FEATURE_KEY,
  initialState: initialNewsManagementState,
  reducers: {
    add: newsManagementAdapter.addOne,
    remove: newsManagementAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNewsList.pending, (state) => {
      state.newsManagementReduxData.isLoading = true;
      state.newsManagementReduxData.loadingStatus = "loading";
    });
  },
});

export const newsManagementReducer = newsManagementSlice.reducer;

export const newsManagementActions = newsManagementSlice.actions;

export const getNewsManagementState = (rootState) =>
  rootState[REDUX_KEYS_ACTIONS.NEWS_MANAGEMENT.FEATURE_KEY];
