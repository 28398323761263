import React, { useState, Suspense, lazy } from "react";
import { useNavigate } from "react-router-dom";
import { CustomTable } from "components/CustomTable";
import CustomModal from "components/CustomModal";
import RenderTableActionsButton from "components/RenderTableActionsButton";
import {
  STRING_CONSTANTS,
  ROUTE_CONSTANTS,
  STATIC_VALUES,
  REDUX_KEYS_ACTIONS,
} from "constants/common";
import "./index.css";
import { useSelector } from "react-redux";

const MainLayout = lazy(() => import("layouts/MainLayout"));
const RenderSubjectMasterFilters = lazy(() =>
  import("./RenderSubjectMasterFilters")
);

const SubjectMaster = () => {
  const { subjectMasterReduxData } = useSelector(
    (state) => state[REDUX_KEYS_ACTIONS.SUBJECT_MASTER.FEATURE_KEY]
  );
  const navigate = useNavigate();
  const defaultModalProps = { isOpen: false, data: null };
  const [isFilter, setIsFilter] = useState(false);
  const [switchChecked, setSwitchChecked] = useState(false);
  const [modal, setModal] = useState(defaultModalProps);
  const [loading] = useState(true);

  const columns = [
    {
      field: "id",
      header: STATIC_VALUES.COLUMNS.COMMON.ID,
    },
    {
      field: "subjectName",
      header: STATIC_VALUES.COLUMNS.SUBJECT_MASTER.SUBJECT_NAME,
      enableSort: true,
    },
    {
      field: "category",
      header: STATIC_VALUES.COLUMNS.COMMON.CATEGORY,
      enableSort: true,
    },
    {
      field: "schoolStatus",
      header: STATIC_VALUES.COLUMNS.COMMON.SCHOOl_STATUS,
      enableSort: true,
    },
    {
      field: "grade",
      header: STATIC_VALUES.COLUMNS.COMMON.GRADE,
      enableSort: true,
    },
    {
      field: "program",
      header: STATIC_VALUES.COLUMNS.COMMON.PROGRAM,
      enableSort: true,
    },
    {
      field: "createdDate",
      header: STATIC_VALUES.COLUMNS.COMMON.CREATED_DATE,
      enableSort: true,
    },
    {
      field: "updatedDate",
      header: STATIC_VALUES.COLUMNS.COMMON.UPDATED_DATE,
      enableSort: true,
    },
    {
      field: "action",
      header: STATIC_VALUES.COLUMNS.COMMON.ACTIONS,
      shouldRenderElement: true,
      renderElement: () => {
        return (
          <RenderTableActionsButton
            setModal={setModal}
            switchChecked={switchChecked}
            handleOnSwitch={handleOnSwitch}
            handleDeleteClick={() => setModal({ data, isOpen: true })}
            showViewIcon
            showEditIcon
            showDeleteIcon
            handleEditRoute={ROUTE_CONSTANTS.EDIT_SUBJECT_MASTER}
            handleViewRoute={ROUTE_CONSTANTS.VIEW_SUBJECT_MASTER}
          />
        );
      },
    },
  ];

  const data = [
    {
      id: "1",
      subjectName: "John Brown",
      category: "Category",
      schoolStatus: "Active",
      grade: "A+",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
      program: "Program",
    },
    {
      id: "2",
      subjectName: "Jim Green",
      category: "Category",
      schoolStatus: "Active",
      grade: "A+",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
      program: "Program",
    },
    {
      id: "3",
      subjectName: "Joe Black",
      category: "Category",
      schoolStatus: "Active",
      grade: "A+",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
      program: "Program",
    },
    {
      id: "4",
      subjectName: "Jim Green",
      category: "Category",
      schoolStatus: "Inactive",
      grade: "A+",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
      program: "Program",
    },
    {
      id: "5",
      subjectName: "Joe Black",
      category: "Category",
      schoolStatus: "Inactive",
      grade: "A+",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
      program: "Program",
    },
    {
      id: "6",
      subjectName: "Jim Green",
      category: "Category",
      schoolStatus: "Active",
      grade: "A+",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
      program: "Program",
    },
    {
      id: "7",
      subjectName: "Joe Black",
      category: "Category",
      schoolStatus: "Inactive",
      grade: "A+",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
      program: "Program",
    },
    {
      id: "8",
      subjectName: "Jim Green",
      category: "Category",
      schoolStatus: "Active",
      grade: "A+",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
      program: "Program",
    },
  ];

  const onChangeData = () => {};

  const multiSelectOptions = [
    { label: "Option 1", value: "option1" },
    { label: "Option 2", value: "option2" },
    { label: "Option 3", value: "option3" },
  ];

  const handleIsFilter = () => {
    setIsFilter(!isFilter);
  };

  const handleAddRoute = (params, error) => {
    navigate(ROUTE_CONSTANTS.ADD_SUBJECT_MASTER);
  };

  const handleOnSwitch = (e, key) => {
    setSwitchChecked(() => e?.target?.checked);
  };

  return (
    <Suspense>
      <MainLayout>
        {modal?.isOpen ? (
          <CustomModal
            modal={modal?.isOpen}
            setModal={() => setModal(defaultModalProps)}
            modalTitle={"Delete Modal"}
            modalInnerContent={STRING_CONSTANTS.COMMON.RECORD_DELETE_STRING}
            footerBtnText={"Yes"}
          />
        ) : null}

        <CustomTable
          showAddButton={true}
          handleAddRoute={handleAddRoute}
          isFilter={isFilter}
          addBtnText={"Add"}
          tableHeader={"Subject Master"}
          handleIsFilter={handleIsFilter}
          filterOptions={
            <Suspense>
              <RenderSubjectMasterFilters
                showDateFilter={true}
                showMultiSelectFilter={true}
                multiSelectOptions={multiSelectOptions}
              />
            </Suspense>
          }
          data={data}
          columns={columns}
          count={10}
          width={"100"}
          noDataFoundMessage={
            STRING_CONSTANTS.SUBJECT_MASTER.NO_DATA_FOR_SUBJECT_MASTER_FOUND
          }
          class={"table_class"}
          onChangeData={onChangeData}
          btnMarginTop={false}
          loading={loading}
        />
      </MainLayout>
    </Suspense>
  );
};

export default SubjectMaster;
