import React, { useState, Suspense, lazy } from "react";
import { useNavigate } from "react-router-dom";
import CustomModal from "components/CustomModal";
import { CustomTable } from "components/CustomTable";
import RenderTableActionsButton from "components/RenderTableActionsButton";
import {
  STRING_CONSTANTS,
  ROUTE_CONSTANTS,
  STATIC_VALUES,
  REDUX_KEYS_ACTIONS,
} from "constants/common";
import { useSelector } from "react-redux";

const MainLayout = lazy(() => import("layouts/MainLayout"));
const RenderBannerManagementFilters = lazy(() =>
  import("./RenderBannerManagementFilters")
);

const BannerManagement = () => {
  const { bannerManagementReduxData } = useSelector(
    (state) => state[REDUX_KEYS_ACTIONS.BANNER_MANAGEMENT.FEATURE_KEY]
  );
  const navigate = useNavigate();
  const defaultModalProps = { isOpen: false, data: null };
  const [isFilter, setIsFilter] = useState(false);
  const [switchChecked, setSwitchChecked] = useState(false);
  const [modal, setModal] = useState(defaultModalProps);

  const columns = [
    {
      field: "id",
      header: STATIC_VALUES.COLUMNS.COMMON.ID,
      customHeaderWidth: "3",
    },
    {
      field: "bannerTitle",
      header: STATIC_VALUES.COLUMNS.BANNER_MANAGEMENT.BANNER_TITLE,
      enableSort: true,
      customHeaderWidth: "12",
    },
    {
      field: "bannerImageIconEnglish",
      header: STATIC_VALUES.COLUMNS.BANNER_MANAGEMENT.BANNER_IMAGE_ENGLISH,
      enableSort: true,
    },
    {
      field: "bannerImageIconFrench",
      header: STATIC_VALUES.COLUMNS.BANNER_MANAGEMENT.BANNER_IAMGE_FRENCH,
      enableSort: true,
    },
    {
      field: "bannerThumbnail",
      header: STATIC_VALUES.COLUMNS.BANNER_MANAGEMENT.BANNER_THUMBNAIL,
      enableSort: true,
    },
    {
      field: "bannerSequence",
      header: STATIC_VALUES.COLUMNS.BANNER_MANAGEMENT.BANNER_SEQUENCE,
      enableSort: true,
      customHeaderWidth: "10",
    },
    {
      field: "bannerPlace",
      header: STATIC_VALUES.COLUMNS.BANNER_MANAGEMENT.BANNER_PLACE,
      enableSort: true,
      customHeaderWidth: "10",
    },

    {
      field: "defaultBannerFlag",
      header: STATIC_VALUES.COLUMNS.BANNER_MANAGEMENT.DEFAULT_BANNER_FLAG,
      enableSort: true,
      customHeaderWidth: "10",
    },
    {
      field: "createdBy",
      header: STATIC_VALUES.COLUMNS.COMMON.CREATED_BY,
      customHeaderWidth: "10",
    },
    {
      field: "createdAt",
      header: STATIC_VALUES.COLUMNS.COMMON.CREATED_AT,
      enableSort: true,
      customHeaderWidth: "10",
    },
    {
      field: "updatedBy",
      header: STATIC_VALUES.COLUMNS.COMMON.UPDATED_BY,
      enableSort: true,
      customHeaderWidth: "10",
    },
    {
      field: "updatedAt",
      header: STATIC_VALUES.COLUMNS.COMMON.UPDATED_AT,
      enableSort: true,
      customHeaderWidth: "10",
    },
    {
      field: "action",
      header: STATIC_VALUES.COLUMNS.COMMON.ACTIONS,
      shouldRenderElement: true,
      renderElement: () => {
        return (
          <RenderTableActionsButton
            setModal={setModal}
            switchChecked={switchChecked}
            handleOnSwitch={handleOnSwitch}
            handleDeleteClick={() => setModal({ data, isOpen: true })}
            handleEditRoute={ROUTE_CONSTANTS.EDIT_BANNER}
            showEditIcon
            showDeleteIcon
          />
        );
      },
    },
  ];

  const data = [
    {
      id: "1",
      bannerTitle: "John Brown",
      bannerImageIconFrench: "avc",
      bannerThumbnail: "Active",
      bannerImageIconEnglish: "jkj",
      bannerSequence: "formal",
      bannerPlace: "ijiij",
      defaultBannerFlag: "gg",
      createdBy: "Riddhi",
      createdAt: "12/09/2024",
      updatedBy: "18/10/2024",
      updatedAt: "18/10/2024",
    },
    {
      id: "2",
      bannerTitle: "Jim Green",
      bannerImageIconFrench: "avc",
      bannerThumbnail: "Active",
      bannerImageIconEnglish: "jkj",
      bannerSequence: "formal",
      defaultBannerFlag: "gg",
      bannerPlace: "ijiij",
      createdBy: "Riddhi",
      createdAt: "12/09/2024",
      updatedBy: "18/10/2024",
      updatedAt: "18/10/2024",
    },
    {
      id: "3",
      bannerTitle: "Joe Black",
      bannerImageIconFrench: "avc",
      bannerThumbnail: "Active",
      bannerImageIconEnglish: "jkj",
      bannerSequence: "formal",
      bannerPlace: "ijiij",
      defaultBannerFlag: "gg",
      createdBy: "Riddhi",
      createdAt: "12/09/2024",
      updatedBy: "18/10/2024",
      updatedAt: "18/10/2024",
    },
    {
      id: "4",
      bannerTitle: "Jim Green",
      bannerImageIconFrench: "avc",
      bannerThumbnail: "Inactive",
      bannerImageIconEnglish: "jkj",
      bannerSequence: "formal",
      bannerPlace: "ijiij",
      defaultBannerFlag: "gg",
      createdBy: "Riddhi",
      createdAt: "12/09/2024",
      updatedBy: "18/10/2024",
      updatedAt: "18/10/2024",
    },
    {
      id: "5",
      bannerTitle: "Joe Black",
      bannerImageIconFrench: "avc",
      bannerThumbnail: "Inactive",
      bannerImageIconEnglish: "jkj",
      bannerSequence: "formal",
      bannerPlace: "ijiij",
      createdBy: "Riddhi",
      createdAt: "12/09/2024",
      updatedBy: "18/10/2024",
      updatedAt: "18/10/2024",
    },
    {
      id: "6",
      bannerTitle: "Jim Green",
      bannerImageIconFrench: "avc",
      bannerThumbnail: "Active",
      bannerImageIconEnglish: "jkj",
      bannerSequence: "formal",
      bannerPlace: "ijiij",
      defaultBannerFlag: "gg",
      createdBy: "Riddhi",
      createdAt: "12/09/2024",
      updatedBy: "18/10/2024",
      updatedAt: "18/10/2024",
    },
    {
      id: "7",
      bannerTitle: "Joe Black",
      bannerImageIconFrench: "avc",
      bannerThumbnail: "Inactive",
      bannerImageIconEnglish: "jkj",
      bannerSequence: "formal",
      bannerPlace: "ijiij",
      defaultBannerFlag: "gg",
      createdBy: "Riddhi",
      createdAt: "12/09/2024",
      updatedBy: "18/10/2024",
      updatedAt: "18/10/2024",
    },
    {
      id: "8",
      bannerTitle: "Jim Green",
      bannerImageIconFrench: "avc",
      bannerThumbnail: "Active",
      bannerImageIconEnglish: "jkj",
      bannerSequence: "formal",
      bannerPlace: "ijiij",
      defaultBannerFlag: "gg",
      createdBy: "Riddhi",
      createdAt: "12/09/2024",
      updatedBy: "18/10/2024",
      updatedAt: "18/10/2024",
    },
  ];

  const onChangeData = () => {};

  const multiSelectOptions = [
    { label: "Option 1", value: "option1" },
    { label: "Option 2", value: "option2" },
    { label: "Option 3", value: "option3" },
  ];

  const handleIsFilter = () => {
    setIsFilter(!isFilter);
  };

  const handleAddRoute = (params, error) => {
    navigate(ROUTE_CONSTANTS.ADD_BANNER);
  };

  const handleOnSwitch = (e, key) => {
    setSwitchChecked(() => e?.target?.checked);
  };

  return (
    <Suspense>
      <MainLayout>
        {modal?.isOpen ? (
          <CustomModal
            modal={modal?.isOpen}
            setModal={() => setModal(defaultModalProps)}
            modalTitle={"Delete Modal"}
            modalInnerContent={STRING_CONSTANTS.COMMON.RECORD_DELETE_STRING}
            footerBtnText={"Yes"}
          />
        ) : null}
        <CustomTable
          showAddButton={true}
          handleAddRoute={handleAddRoute}
          isFilter={isFilter}
          addBtnText={"Add"}
          tableHeader={"Banner Management"}
          handleIsFilter={handleIsFilter}
          filterOptions={
            <Suspense>
              <RenderBannerManagementFilters
                showDateFilter={true}
                showMultiSelectFilter={true}
                multiSelectOptions={multiSelectOptions}
              />
            </Suspense>
          }
          data={data}
          columns={columns}
          count={10}
          width={"100"}
          noDataFoundMessage={
            STRING_CONSTANTS.BANNER_MASTER.NO_DATA_FOR_BANNER_MANAGEMENT_FOUND
          }
          class={"table_class"}
          onChangeData={onChangeData}
          btnMarginTop={false}
        />
      </MainLayout>
    </Suspense>
  );
};

export default BannerManagement;
