export const getArticleSchema = (languages) => {
  const initialSchema = {};

  if (languages?.length > 0) {
    languages?.forEach((language) => {
      initialSchema[language?.languageCode] = {
        title: {
          value: "",
          error: "",
          required: true,
        },
        description: {
          value: "",
          error: "",
          required: true,
        },
        thumbNailImage: {
          value: "",
          error: "",
          required: true,
        },
      };
    });
  }

  return {
    ...initialSchema,
    status: {
      value: "Active",
      error: "",
      required: true,
    },
  };
};
