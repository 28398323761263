import styled from "styled-components";

const PreviewArea = styled.aside`
  margin-top: 1rem;
`;

const RemoveFileIcon = styled.div`
  cursor: pointer;
`;

const UploadedFileName = styled.div`
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const EachFile = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.showError {
    color: red;
  }
`;

const ThumbImageHolder = styled.div`
  display: block;
  margin: 0px auto;
  padding: 0px;
`;

export const CustomFileInputStyles = {
  PreviewArea,
  RemoveFileIcon,
  UploadedFileName,
  EachFile,
  ThumbImageHolder,
};
