import React, { lazy } from "react";

function CustomImage(props) {
  return (
    <img
      loading={lazy}
      src={props?.url}
      alt={props.alt ? props.alt : ""}
      width={props?.width ? props?.width : ""}
      height={props?.height ? props?.height : ""}
      style={props?.style ? props?.style : {}}
      className={props?.className ? props?.className : ""}
      crossOrigin={props?.urlOnly ? null : ""}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null;
      }}
    />
  );
}

export default CustomImage;
