import React, { Suspense, lazy, useState } from "react";
import CustomModal from "components/CustomModal";
import RenderTableActionsButton from "components/RenderTableActionsButton";
import {
  ROUTE_CONSTANTS,
  STATIC_VALUES,
  STRING_CONSTANTS,
} from "constants/common";
import { CustomTable } from "components/CustomTable";
import "./index.css";

const MainLayout = lazy(() => import("layouts/MainLayout"));
const RenderSchoolUserStudentFilters = lazy(() =>
  import("./RenderSchoolUserStudentFilters")
);

const SchoolUserStudent = () => {
  const [isFilter, setIsFilter] = useState(false);
  const [switchChecked, setSwitchChecked] = useState(false);
  const defaultModalProps = { isOpen: false, data: null };
  const [modal, setModal] = useState(defaultModalProps);

  const columns = [
    {
      field: "userId",
      header: STATIC_VALUES.COLUMNS.COMMON.ID,
    },
    {
      field: "userName",
      header: STATIC_VALUES.COLUMNS.COMMON.USER_NAME,
      enableSort: true,
    },
    {
      field: "gender",
      header: STATIC_VALUES.COLUMNS.COMMON.GENDER,
      enableSort: true,
    },
    {
      field: "schoolName",
      header: STATIC_VALUES.COLUMNS.COMMON.SCHOOL_NAME,
      enableSort: true,
    },
    {
      field: "educationType",
      header: STATIC_VALUES.COLUMNS.COMMON.EDUCATION_TYPE,
      enableSort: true,
    },
    {
      field: "program",
      header: STATIC_VALUES.COLUMNS.COMMON.PROGRAM,
      enableSort: true,
    },
    {
      field: "grade",
      header: STATIC_VALUES.COLUMNS.COMMON.GRADE,
      enableSort: true,
    },
    {
      field: "subjects",
      header: STATIC_VALUES.COLUMNS.COMMON.SUBJECTS,
      enableSort: true,
    },
    {
      field: "cycle",
      header:
        STATIC_VALUES.COLUMNS.SCHOOL_USER_STUDENT.SCHOOL_USER_STUDENT_CYCLE,
      enableSort: true,
    },
    {
      field: "parentName",
      header: STATIC_VALUES.COLUMNS.COMMON.PARENT_NAME,
      enableSort: true,
    },
    {
      field: "emailId",
      header: STATIC_VALUES.COLUMNS.COMMON.EMAIL_ID,
      enableSort: true,
    },
    {
      field: "contactInformation",
      header: STATIC_VALUES.COLUMNS.COMMON.CONATACT_INFORMATION,
      enableSort: true,
    },
    {
      field: "region",
      header: STATIC_VALUES.COLUMNS.COMMON.REGION,
      enableSort: true,
    },
    {
      field: "province",
      header: STATIC_VALUES.COLUMNS.COMMON.PROVINCE,
      enableSort: true,
    },
    {
      field: "commune",
      header: STATIC_VALUES.COLUMNS.COMMON.COMMUNE,
      enableSort: true,
    },
    {
      field: "registeredAt",
      header: STATIC_VALUES.COLUMNS.COMMON.REGISTERED_AT,
      enableSort: true,
    },
    {
      field: "action",
      header: STATIC_VALUES.COLUMNS.COMMON.ACTIONS,
      shouldRenderElement: true,
      renderElement: () => {
        return (
          <RenderTableActionsButton
            setModal={setModal}
            switchChecked={switchChecked}
            handleOnSwitch={handleOnSwitch}
            handleDeleteClick={() => setModal({ data, isOpen: true })}
            showViewIcon={true}
            showDeleteIcon={true}
            handleViewRoute={ROUTE_CONSTANTS.VIEW_SCHOOL_USER_STUDENT}
          />
        );
      },
    },
  ];

  const data = [
    {
      userId: "1",
      userName: "Student one",
      gender: "Male",
      schoolName: "Saint Mary",
      educationType: "Primary",
      program: "First Class",
      grade: "A+",
      subjects: "Science",
      cycle: "Quaterly",
      parentName: "Kane",
      emailId: "demo@gmail.com",
      contactInformation: "9876098789",
      region: "Region",
      province: "Province",
      commune: "Commune",
      registeredAt: "15-oct-2024",
    },
    {
      userId: "2",
      userName: "Student Two",
      gender: "Male",
      schoolName: "Saint Mary",
      educationType: "Secondary",
      program: "Second Class",
      grade: "B+",
      subjects: "Maths",
      cycle: "Quaterly",
      parentName: "Jene",
      emailId: "demo@gmail.com",
      contactInformation: "9876098789",
      region: "Region",
      province: "Province",
      commune: "Commune",
      registeredAt: "15-oct-2024",
    },
    {
      userId: "3",
      userName: "Student Three",
      gender: "Male",
      schoolName: "Saint Mary",
      educationType: "Primary",
      program: "First Class",
      grade: "A+",
      subjects: "Science",
      cycle: "Quaterly",
      parentName: "Kane",
      emailId: "demo@gmail.com",
      contactInformation: "9876098789",
      region: "Region",
      province: "Province",
      commune: "Commune",
      registeredAt: "15-oct-2024",
    },
    {
      userId: "4",
      userName: "Student one",
      gender: "Male",
      schoolName: "Saint Mary",
      educationType: "Primary",
      program: "First Class",
      grade: "A+",
      subjects: "Science",
      cycle: "Quaterly",
      parentName: "Kane",
      emailId: "demo@gmail.com",
      contactInformation: "9876098789",
      region: "Region",
      province: "Province",
      commune: "Commune",
      registeredAt: "15-oct-2024",
    },
    {
      userId: "5",
      userName: "Student one",
      gender: "Male",
      schoolName: "Saint Mary",
      educationType: "Primary",
      program: "First Class",
      grade: "A+",
      subjects: "Science",
      cycle: "Quaterly",
      parentName: "Kane",
      emailId: "demo@gmail.com",
      contactInformation: "9876098789",
      region: "Region",
      province: "Province",
      commune: "Commune",
      registeredAt: "15-oct-2024",
    },
    {
      userId: "6",
      userName: "Student 6",
      gender: "Male",
      schoolName: "Saint Mary",
      educationType: "Primary",
      program: "First Class",
      grade: "A+",
      subjects: "Science",
      cycle: "Quaterly",
      parentName: "Kane",
      emailId: "demo@gmail.com",
      contactInformation: "9876098789",
      region: "Region",
      province: "Province",
      commune: "Commune",
      registeredAt: "15-oct-2024",
    },
    {
      userId: "7",
      userName: "Student 7",
      gender: "Male",
      schoolName: "Saint Mary",
      educationType: "Primary",
      program: "First Class",
      grade: "A+",
      subjects: "Science",
      cycle: "Quaterly",
      parentName: "Kane",
      emailId: "demo@gmail.com",
      contactInformation: "9876098789",
      region: "Region",
      province: "Province",
      commune: "Commune",
      registeredAt: "15-oct-2024",
    },
    {
      userId: "8",
      userName: "Student eight",
      gender: "Male",
      schoolName: "Saint Mary",
      educationType: "Primary",
      program: "First Class",
      grade: "A+",
      subjects: "Science",
      cycle: "Quaterly",
      parentName: "Kane",
      emailId: "demo@gmail.com",
      contactInformation: "9876098789",
      region: "Region",
      province: "Province",
      commune: "Commune",
      registeredAt: "15-oct-2024",
    },
  ];

  const onChangeData = () => {};

  const multiSelectOptions = [
    { label: "Option 1", value: "option1" },
    { label: "Option 2", value: "option2" },
    { label: "Option 3", value: "option3" },
  ];

  const handleIsFilter = () => {
    setIsFilter(!isFilter);
  };

  const handleOnSwitch = (e, key) => {
    setSwitchChecked(() => e?.target?.checked);
  };

  return (
    <Suspense>
      <MainLayout>
        {modal?.isOpen ? (
          <CustomModal
            modal={modal?.isOpen}
            setModal={() => setModal(defaultModalProps)}
            modalTitle={"Delete Modal"}
            modalInnerContent={STRING_CONSTANTS.COMMON.RECORD_DELETE_STRING}
            footerBtnText={STRING_CONSTANTS.COMMON.BUTTON.YES_TITLE}
          />
        ) : null}
        <CustomTable
          showExportButton={true}
          isFilter={isFilter}
          tableHeader={
            STRING_CONSTANTS.SCHOOL_USER_STUDENT
              .HEADER_TITLE_SCHOOL_USER_STUDENT
          }
          handleIsFilter={handleIsFilter}
          filterOptions={
            <Suspense>
              <RenderSchoolUserStudentFilters
                showDateFilter={true}
                showMultiSelectFilter={true}
                multiSelectOptions={multiSelectOptions}
              />
            </Suspense>
          }
          data={data}
          columns={columns}
          count={10}
          width={"100"}
          noDataFoundMessage={
            STRING_CONSTANTS.SCHOOL_USER_STUDENT
              .NO_DATA_FOR_SCHOOL_USER_STUDENT_FOUND
          }
          class={"table_class"}
          onChangeData={onChangeData}
          btnMarginTop={false}
        />
      </MainLayout>
    </Suspense>
  );
};

export default SchoolUserStudent;
