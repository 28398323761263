import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "hooks/useForm";
import CustomDropdown from "components/CustomDropdown";
import CustomInput from "components/CustomInput";
import CustomTextarea from "components/CustomTextarea";
import { STRING_CONSTANTS, ROUTE_CONSTANTS } from "constants/common";
import { StyledFlex } from "constants/commonStyles";
import { FieldTypes } from "constants/fieldTypes";
import FormLayout from "layouts/FormLayout";
import MainLayout from "layouts/MainLayout";
import CustomConfirmModal from "components/CustomConfirmModal";
import useDocumentTitle from "hooks/useDocumentTitle";

const ProgramMasterForm = (props) => {
  const { formHeading, mode } = props;
  const [initialReqData, setInitialReqData] = useState({});
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const navigate = useNavigate();
  const PageTitle =
    mode === STRING_CONSTANTS.COMMON.MODE.ADD
      ? STRING_CONSTANTS.PROGRAM_MASTER.ADD_PROGRAM_MASTER
      : mode === STRING_CONSTANTS.COMMON.MODE.VIEW
      ? STRING_CONSTANTS.PROGRAM_MASTER.VIEW_PROGRAM_MASTER
      : STRING_CONSTANTS.PROGRAM_MASTER.EDIT_PROGRAM_MASTER;
  useDocumentTitle(`${STRING_CONSTANTS.COMMON.EDULAC_TITLE}-${PageTitle}`);

  const initialState = {
    programNameInEnglish: {
      value: "",
      error: "",
      required: true,
    },
    descriptionInEnglish: {
      value: "",
      error: "",
      required: true,
    },
    programNameInFrench: {
      value: "",
      error: "",
      required: true,
    },
    descriptionInFrench: {
      value: "",
      error: "",
      required: true,
    },
    schoolType: {
      value: "",
      error: "",
      required: true,
    },
    grade: {
      value: "",
      error: "",
      required: true,
    },
    schoolCategory: {
      value: "",
      error: "",
      required: true,
    },
    schoolStatus: {
      value: "",
      error: "",
      required: true,
    },
    schoolEducationType: {
      value: "",
      error: "",
      required: true,
    },
  };

  const handleGradeMasterSubmission = (params, error) => {
    if (mode) {
    } else {
    }
  };

  const {
    errors,
    dirty,
    values,
    handleOnSubmit,
    handleOnChange,
    handleOnSelection,
    clearStateSchema,
  } = useForm(initialState, handleGradeMasterSubmission);

  useEffect(() => {
    setInitialReqData(values);
  }, [values]);

  const options = [
    { value: "option1", label: "Option 1" },
    { value: "option2", label: "Option 2" },
    { value: "option3", label: "Option 3" },
  ];

  const commonTextAreaProps = {
    type: FieldTypes.TYPE_TEXT,
    placeholder: "Add Description.",
    onChangeHandler: handleOnChange,
    mode: mode,
    textareaClass: "program-master-textarea-content",
    required: true,
    rows: "3",
    cols: "4",
  };

  const commonInputProps = {
    onChangeHandler: handleOnChange,
    mode: mode,
    required: true,
    type: FieldTypes.TYPE_TEXT,
  };

  const commonDropDownProps = {
    placeholder: "Select",
    options: options,
    onChangeHandler: handleOnSelection,
    allowClear: true,
    mode: mode,
    required: true,
  };

  const onCancelClickHandler = () => {
    const formDirty = Object.keys(dirty)?.some((key) => dirty[key]);
    if (
      !formDirty &&
      JSON.stringify(initialReqData) === JSON.stringify(values)
    ) {
      clearStateSchema();
      navigate(ROUTE_CONSTANTS.PROGRAM_MASTER);
    } else {
      setShowConfirmationPopup(true);
    }
  };

  return (
    <MainLayout>
      <FormLayout
        formHeading={formHeading}
        handleOnSubmit={handleOnSubmit}
        cancelBtnText={STRING_CONSTANTS.COMMON.BUTTON.CANCEL_TITLE}
        cancelBtnRoute={ROUTE_CONSTANTS.PROGRAM_MASTER}
        saveBtnText={
          mode === STRING_CONSTANTS.COMMON.MODE.ADD
            ? STRING_CONSTANTS.COMMON.BUTTON.SAVE_TITLE
            : STRING_CONSTANTS.COMMON.BUTTON.UPDATE_TITLE
        }
        mode={mode}
        viewButtonText={STRING_CONSTANTS.COMMON.BUTTON.EDIT_TITLE}
        viewFormEditRoute={ROUTE_CONSTANTS.EDIT_PROGRAM_MASTER}
        onCancel={onCancelClickHandler}
      >
        <StyledFlex>
          <CustomInput
            name="programNameInEnglish"
            placeholder="Program Name"
            label={"Grade Name" + STRING_CONSTANTS.COMMON.ENGLISH_LABEL}
            value={values?.programNameInEnglish}
            hasError={
              errors?.programNameInEnglish && dirty?.programNameInEnglish
            }
            errorMessage={errors?.programNameInEnglish}
            {...commonInputProps}
          />
          <CustomInput
            name="programNameInFrench"
            placeholder="Program Name"
            label={"Grade Name" + STRING_CONSTANTS.COMMON.FRENCH_LABEL}
            mode={mode}
            value={values?.programNameInFrench}
            hasError={errors?.programNameInFrench && dirty?.programNameInFrench}
            errorMessage={errors?.programNameInFrench}
            {...commonInputProps}
          />
        </StyledFlex>
        <StyledFlex>
          <CustomTextarea
            name="descriptionInEnglish"
            label={"Add Description." + STRING_CONSTANTS.COMMON.ENGLISH_LABEL}
            value={values?.descriptionInEnglish}
            hasError={
              errors?.descriptionInEnglish && dirty?.descriptionInEnglish
            }
            errorMessage={errors?.descriptionInEnglish}
            {...commonTextAreaProps}
          />
          <CustomTextarea
            name="descriptionInFrench"
            label={"Add Description." + STRING_CONSTANTS.COMMON.FRENCH_LABEL}
            value={values?.descriptionInFrench}
            hasError={errors?.descriptionInFrench && dirty?.descriptionInFrench}
            errorMessage={errors?.descriptionInFrench}
            {...commonTextAreaProps}
          />
        </StyledFlex>
        <StyledFlex>
          <CustomDropdown
            fieldName={"schoolType"}
            label={"Select School Type"}
            onChangeHandler={handleOnSelection}
            value={values?.schoolType}
            hasError={errors?.schoolType && dirty?.schoolType}
            errorMessage={errors?.schoolType}
            placeholder="Select School Type"
            {...commonDropDownProps}
          />
          <CustomDropdown
            fieldName={"grade"}
            label={"Select Grade"}
            value={values?.grade}
            placeholder="Select Grade"
            hasError={errors?.grade && dirty?.grade}
            errorMessage={errors?.grade}
            {...commonDropDownProps}
          />
        </StyledFlex>
        <StyledFlex>
          <CustomDropdown
            fieldName={"schoolCategory"}
            label={"Select School Category"}
            placeholder="Select School Category"
            value={values?.schoolCategory}
            hasError={errors?.schoolCategory && dirty?.schoolCategory}
            errorMessage={errors?.schoolCategory}
            {...commonDropDownProps}
          />
          <CustomDropdown
            fieldName={"schoolStatus"}
            label={"Select School Status"}
            placeholder="Select School Status"
            value={values?.schoolStatus}
            hasError={errors?.schoolStatus && dirty?.schoolStatus}
            errorMessage={errors?.schoolStatus}
            {...commonDropDownProps}
          />
        </StyledFlex>
        <StyledFlex>
          <CustomDropdown
            fieldName={"schoolEducationType"}
            label={"Select School Education Type"}
            value={values?.schoolEducationType}
            hasError={errors?.schoolEducationType && dirty?.schoolEducationType}
            errorMessage={errors?.schoolEducationType}
            placeholder="Select School Education Type"
            {...commonDropDownProps}
          />
        </StyledFlex>
      </FormLayout>
      <CustomConfirmModal
        show={showConfirmationPopup}
        title={STRING_CONSTANTS.COMMON.TITLE_CONFIRMATION_BACK_MESSAGE}
        onCancel={() => setShowConfirmationPopup(false)}
        handleOnConfirm={() => navigate(ROUTE_CONSTANTS.PROGRAM_MASTER)}
      />
    </MainLayout>
  );
};

export default ProgramMasterForm;
