import React, { useEffect, useRef, useState } from "react";
import { CustomFileInputStyles } from "./CustomFileInputStyles";
import { STRING_CONSTANTS } from "constants/common";
import { IconDelete, IconFieldHelp } from "assets/images/SVGs";
import CustomImage from "components/CustomImage";
import "./FileInputStyles.css";

export function CustomFileInput(props) {
  const {
    fieldName,
    title,
    description = "",
    hasError = false,
    errorMessage = "",
    hasPreview = false,
    onImageClearHandler,
    onChangeHandler,
    preview = "",
    fileNameToDisplay = "",
    accepts,
    isDragAndDropRequired,
    multiple,
    disabled = false,
    urlOnly = false,
    view = false,
    required = false,
    className = "",
    style = {},
    isReadOnly = false,
    disableAutoFocus = false,
    isFileAllFormat = false,
  } = props;

  const [showHint, setShowHint] = useState(false);
  const [currentFiles, setCurrentFiles] = useState([]);
  const [setPrevious] = useState({});
  const [setIndex] = useState(0);
  const inputRef = useRef(null);

  useEffect(() => {
    if (!multiple && preview) {
      if (preview?.includes(":"))
        setCurrentFiles([{ name: preview?.split(":")[1] }]);
      else {
        setCurrentFiles([{ name: preview }]);
      }
    }
  }, []);

  const onClickDelete = (index, prev) => {
    setIndex(index);
    setPrevious(prev);
  };

  const hintHoverHandler = () => {
    setShowHint(!showHint);
  };

  const onImageChange = (e) => {
    const files = [...e?.target?.files];
    setCurrentFiles(files);
    if (!multiple && files[0]) {
      onChangeHandler(files[0]);
    } else {
      if (files) {
        onChangeHandler(files);
      }
    }
  };

  const upload = () => {
    inputRef.current.click();
  };

  const handleDragOver = (event) => {
    event?.preventDefault();
  };

  const handleDrop = (event) => {
    event?.preventDefault();
    if (!disabled) {
      if (!multiple) {
        if (event?.dataTransfer?.files[0])
          onChangeHandler(event.dataTransfer.files[0]);
      } else {
        if (event?.dataTransfer?.files)
          onChangeHandler(event.dataTransfer.files);
      }
    }
  };

  const renderImages = (url) => {
    return (
      <CustomFileInputStyles.ThumbImageHolder>
        <CustomImage url={url} urlOnly={urlOnly ? urlOnly : null} />
      </CustomFileInputStyles.ThumbImageHolder>
    );
  };

  const renderAllFormatBlobFiles = (fileList = []) => {
    return fileList?.map((file, index) => {
      return (
        <CustomFileInputStyles.EachFile
          className={`${hasError ? "showError" : ""}`}
          key={index}
        >
          {!view ? (
            <CustomFileInputStyles.RemoveFileIcon
              className="file-input-delete-icon"
              onClick={() => onClickDelete(index, file?.blobURL)}
            >
              <IconDelete />
            </CustomFileInputStyles.RemoveFileIcon>
          ) : null}
        </CustomFileInputStyles.EachFile>
      );
    });
  };

  return (
    <div
      className={`field-group ${hasError ? "has-error" : ""} ${className}`}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <label className="field-group-label">
        <span>
          {title}
          {!isReadOnly ? (
            required ? (
              <span className="required-asterisk">*</span>
            ) : null
          ) : null}
        </span>
        {!isReadOnly ? (
          description?.length > 0 ? (
            <span
              className={`hint ${showHint && "show"}`}
              onMouseOver={hintHoverHandler}
              onMouseOut={hintHoverHandler}
            >
              <IconFieldHelp />
              <span className="hint-info">
                {description?.length > 0
                  ? description
                  : STRING_CONSTANTS.COMMON.DEFAULT_IMAGE_DESCRIPTION}
              </span>
            </span>
          ) : null
        ) : null}
      </label>
      {description?.length > 0 && (
        <span className="field-text">{description}</span>
      )}
      {!view ? (
        <div className="dropArea">
          <input
            ref={inputRef}
            accept={accepts}
            multiple={multiple && !isFileAllFormat}
            type="file"
            autoComplete="off"
            tabIndex="-1"
            onChange={onImageChange}
            name={fieldName}
            id={fieldName}
            disabled={disabled}
            value=""
            autoFocus={!disableAutoFocus}
            className="file-display-none"
          />

          {isDragAndDropRequired === true ? (
            <p>Drag 'n' drop some {multiple ? "files" : "file"} here</p>
          ) : (
            <p>Upload file</p>
          )}
          <button
            type="button"
            className={disabled ? "btn disabled" : ""}
            disabled={disabled}
            onClick={upload}
          >
            <span>Open File Dialog</span>
          </button>
        </div>
      ) : null}
      {hasError ? <p className="file-error-msg">{errorMessage} </p> : null}
      {hasPreview ? (
        <CustomFileInputStyles.PreviewArea>
          {isFileAllFormat
            ? renderAllFormatBlobFiles(currentFiles)
            : renderImages(preview)}
        </CustomFileInputStyles.PreviewArea>
      ) : null}
      {view && !hasPreview ? (
        <div>{STRING_CONSTANTS.COMMON.NO_DOCUMENTS_UPLOADED}</div>
      ) : null}
    </div>
  );
}

export default CustomFileInput;
