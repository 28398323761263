import { Modal } from "antd";
import CustomButton from "components/CustomButton";
import React from "react";

const CustomConfirmModal = (props) => {
  const {
    title = "",
    onCancel,
    handleOnConfirm,
    shouldDisable,
    primaryBtnName = "",
    secondaryBtnName = "",
    modalInnerContent,
    show,
  } = props;

  return (
    <Modal
      title={title}
      centered
      open={show}
      onOk={handleOnConfirm}
      onCancel={onCancel}
      footer={[
        <div className="modal_footer_wrapper">
          <CustomButton
            btnText={primaryBtnName ? primaryBtnName : "Confirm"}
            onClickHandler={handleOnConfirm}
            shouldDisable={shouldDisable}
          />
          <CustomButton
            btnText={secondaryBtnName ? secondaryBtnName : "Cancel"}
            onClickHandler={onCancel}
            shouldDisable={shouldDisable}
          />
        </div>,
      ]}
    >
      <p className="modal_inner_content">{modalInnerContent}</p>
    </Modal>
  );
};

export default CustomConfirmModal;
