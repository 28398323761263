import React, { useState } from "react";
import SideNav from "components/DashboardSideNav";
import NavBar from "components/NavBar";
import "./index.css";
import { MainLayoutLeftPanel, MainLayoutRightPanel } from "./MainLayoutStyles";

const MainLayout = ({ children }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <div className={"main-layout-wrapper"}>
      <div className={"main-layout-inner-wrapper"}>
        <MainLayoutLeftPanel isCollapsed={isCollapsed}>
          <SideNav isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
        </MainLayoutLeftPanel>
        <MainLayoutRightPanel isCollapsed={isCollapsed}>
          <NavBar
            toggleSidebar={() => setIsCollapsed(!isCollapsed)}
            isCollapsed={isCollapsed}
          />
          <div className={"main-layout-children-wrapper"}>{children}</div>
        </MainLayoutRightPanel>
      </div>
    </div>
  );
};

export default MainLayout;
