import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { REDUX_KEYS_ACTIONS } from "constants/common";

export const schoolUserTeacherAdapter = createEntityAdapter();

export const fetchSchoolUserTeacherList = createAsyncThunk(
  REDUX_KEYS_ACTIONS.SCHOOL_USER_TEACHER.FETCH_SCHOOL_USER_TEACHER_LIST,
  async () => {
    const response = await fetch("");
    return response?.json();
  }
);

export const initialSchoolUserTeacherState = {
  schoolUserTeacherReduxData: {
    loadingStatus: "not loaded",
    error: null,
    isLoading: false,
  },
};

export const schoolUserTeacherSlice = createSlice({
  name: REDUX_KEYS_ACTIONS.SCHOOL_USER_TEACHER.FEATURE_KEY,
  initialState: initialSchoolUserTeacherState,
  reducers: {
    add: schoolUserTeacherAdapter.addOne,
    remove: schoolUserTeacherAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSchoolUserTeacherList.pending, (state) => {
      state.schoolUserTeacherReduxData.isLoading = true;
      state.schoolUserTeacherReduxData.loadingStatus = "loading";
    });
  },
});

export const schoolUserTeacherReducer = schoolUserTeacherSlice.reducer;

export const schoolUserTeacherActions = schoolUserTeacherSlice.actions;

export const getSchoolUserTeacherState = (rootState) =>
  rootState[REDUX_KEYS_ACTIONS.SCHOOL_USER_TEACHER.FEATURE_KEY];
