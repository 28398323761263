import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { REDUX_KEYS_ACTIONS } from "constants/common";

export const masterManagementAdapter = createEntityAdapter();

export const fetchMasterList = createAsyncThunk(
  REDUX_KEYS_ACTIONS.MASTER_MANAGEMENT.FETCH_MASTER_LIST,
  async () => {
    const response = await fetch("");
    return response?.json();
  }
);

export const initialMasterManagementState = {
  masterManagementReduxData: {
    loadingStatus: "not loaded",
    error: null,
    isLoading: false,
  },
};

export const masterManagementSlice = createSlice({
  name: REDUX_KEYS_ACTIONS.MASTER_MANAGEMENT.FEATURE_KEY,
  initialState: initialMasterManagementState,
  reducers: {
    add: masterManagementAdapter.addOne,
    remove: masterManagementAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMasterList.pending, (state) => {
      state.masterManagementReduxData.isLoading = true;
      state.masterManagementReduxData.loadingStatus = "loading";
    });
  },
});

export const masterManagementReducer = masterManagementSlice.reducer;

export const masterManagementActions = masterManagementSlice.actions;

export const getMasterManagementState = (rootState) =>
  rootState[REDUX_KEYS_ACTIONS.MASTER_MANAGEMENT.FEATURE_KEY];
