import { REGEX_STRINGS, STRING_CONSTANTS } from "constants/common";

export const getNewsSchema = (languages) => {
  const initialSchema = {};

  languages?.forEach((language) => {
    initialSchema[language?.languageCode] = {
      newsTitle: {
        value: "",
        error: "",
        required: true,
        validator: {
          func: (value) => REGEX_STRINGS.NEWS_MANAGEMENT.TITLE_NEWS.test(value),
          error: STRING_CONSTANTS.NEWS_MANAGEMENT.FIELD_TITLE_ERROR_MESSAGE,
        },
      },
      newsDescription: {
        value: "",
        error: "",
        required: true,
        validator: {
          func: (value) =>
            REGEX_STRINGS.NEWS_MANAGEMENT.TITLE_DESCRIPTION.test(value),
          error:
            STRING_CONSTANTS.NEWS_MANAGEMENT.FIELD_DESCRIPTION_ERROR_MESSAGE,
        },
      },

      thumbNailImage: {
        value: "",
        error: "",
        required: true,
      },
    };
  });

  return {
    ...initialSchema,
    newsStatus: {
      value: "Active",
      error: "",
      required: true,
    },
    redirectionLink: {
      value: "",
      error: "",
      required: true,
      validator: {
        func: (value) =>
          REGEX_STRINGS.NEWS_MANAGEMENT.TITLE_REDIRECTION_LINK.test(value),
        error:
          STRING_CONSTANTS.NEWS_MANAGEMENT.FIELD_REDIRECTION_LINK_ERROR_MESSAGE,
      },
    },
  };
};
