import React from "react";
import "./index.css";
import { STRING_CONSTANTS } from "constants/common";

export function CustomSwitch(props) {
  const {
    isChecked = false,
    onChangeHandler,
    fieldName,
    title,
    labelClass = "",
    titleClass = "",
    classes,
    disableAutoFocus = false,
    mode = STRING_CONSTANTS.COMMON.MODE.ADD,
  } = props;

  return (
    <div className={`field-group field-inline ${classes ? classes : ""}`}>
      <label className={`field-group-label ${labelClass}`}>
        <b className={titleClass}>{title}</b>
      </label>
      <div className="switch-button">
        <input
          id="checkbox-switch"
          type="checkbox"
          className="checkbox"
          onChange={onChangeHandler}
          name={fieldName}
          checked={isChecked ? "checked" : ""}
          disabled={mode === STRING_CONSTANTS.COMMON.MODE.VIEW}
          autoFocus={!disableAutoFocus}
        />
        <div className="knobs"></div>
        <div className="layer"></div>
      </div>
    </div>
  );
}

export default CustomSwitch;
