import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "hooks/useForm";
import CustomDropdown from "components/CustomDropdown";
import CustomInput from "components/CustomInput";
import CustomTextarea from "components/CustomTextarea";
import { StyledFlex } from "constants/commonStyles";
import FormLayout from "layouts/FormLayout";
import MainLayout from "layouts/MainLayout";
import { STRING_CONSTANTS, ROUTE_CONSTANTS } from "constants/common";
import { FieldTypes } from "constants/fieldTypes";
import CustomConfirmModal from "components/CustomConfirmModal";
import useDocumentTitle from "hooks/useDocumentTitle";
import "./index.css";

const SubjectMasterForm = ({ formHeading, mode }) => {
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [initialReqData, setInitialReqData] = useState({});
  const navigate = useNavigate();
  const PageTitle =
    mode === STRING_CONSTANTS.COMMON.MODE.ADD
      ? STRING_CONSTANTS.SUBJECT_MASTER.ADD_SUBJECT_MASTER
      : mode === STRING_CONSTANTS.COMMON.MODE.VIEW
      ? STRING_CONSTANTS.SUBJECT_MASTER.VIEW_SUBJECT_MASTER
      : STRING_CONSTANTS.SUBJECT_MASTER.EDIT_SUBJECT_MASTER;
  useDocumentTitle(`${STRING_CONSTANTS.COMMON.EDULAC_TITLE}-${PageTitle}`);

  const initialState = {
    subjectNameInEnglish: {
      value: "",
      error: "",
      required: true,
    },
    subjectNameInFrench: {
      value: "",
      error: "",
      required: true,
    },
    descriptionInEnglish: {
      value: "",
      error: "",
      required: true,
    },
    descriptionInFrench: {
      value: "",
      error: "",
      required: true,
    },
    grade: {
      value: "",
      error: "",
      required: true,
    },
    titleInEnglish: {
      value: "",
      error: "",
      required: true,
    },
    titleInFrench: {
      value: "",
      error: "",
      required: true,
    },
    schoolCategory: {
      value: "",
      error: "",
      required: true,
    },
    schoolStatus: {
      value: "",
      error: "",
      required: true,
    },
    type: {
      value: "",
      error: "",
      required: true,
    },
  };

  const handleGradeMasterSubmission = (state, error) => {
    if (mode) {
    } else {
    }
  };

  const {
    errors,
    values,
    dirty,
    handleOnSubmit,
    handleOnChange,
    handleOnSelection,
    clearStateSchema,
  } = useForm(initialState, handleGradeMasterSubmission);

  useEffect(() => {
    setInitialReqData(values);
  }, [values]);

  const options = [
    { value: "option1", label: "Option 1" },
    { value: "option2", label: "Option 2" },
    { value: "option3", label: "Option 3" },
  ];

  const commonInputProps = {
    type: FieldTypes.TYPE_TEXT,
    onChangeHandler: handleOnChange,
    mode: mode,
    required: true,
  };

  const commonTextAreaProps = {
    type: FieldTypes.TYPE_TEXT,
    placeholder: "Add Description.",
    onChangeHandler: handleOnChange,
    mode,
    rows: "2",
    cols: "2",
    required: true,
    textareaClass: "grade-master-textarea-content",
  };

  const commonDropdownProps = {
    options: options,
    onChangeHandler: handleOnSelection,
    allowClear: true,
    mode: mode,
    required: true,
  };

  const onCancelClickHandler = () => {
    const formDirty = Object.keys(dirty)?.some((key) => dirty[key]);
    if (
      !formDirty &&
      JSON.stringify(initialReqData) === JSON.stringify(values)
    ) {
      clearStateSchema();
      navigate(ROUTE_CONSTANTS.SUBJECT_MASTER);
    } else {
      setShowConfirmationPopup(true);
    }
  };

  return (
    <MainLayout>
      <FormLayout
        formHeading={formHeading}
        handleOnSubmit={handleOnSubmit}
        cancelBtnText={STRING_CONSTANTS.COMMON.BUTTON.CANCEL_TITLE}
        cancelBtnRoute={ROUTE_CONSTANTS.SUBJECT_MASTER}
        onCancel={onCancelClickHandler}
        saveBtnText={STRING_CONSTANTS.COMMON.BUTTON.SAVE_TITLE}
      >
        <StyledFlex>
          <CustomInput
            name="subjectNameInEnglish"
            placeholder="Subject Name"
            label={"Subject Name" + STRING_CONSTANTS.COMMON.ENGLISH_LABEL}
            value={values?.subjectNameInEnglish}
            hasError={
              errors?.subjectNameInEnglish && dirty?.subjectNameInEnglish
            }
            errorMessage={errors?.subjectNameInEnglish}
            {...commonInputProps}
          />
          <CustomInput
            name="subjectNameInFrench"
            placeholder="Subject Name"
            label={"Subject Name" + STRING_CONSTANTS.COMMON.FRENCH_LABEL}
            value={values?.subjectNameInFrench}
            hasError={errors?.subjectNameInFrench && dirty?.subjectNameInFrench}
            errorMessage={errors?.subjectNameInFrench}
            {...commonInputProps}
          />
        </StyledFlex>
        <StyledFlex>
          <CustomTextarea
            name="descriptionInEnglish"
            label={"Add Description." + STRING_CONSTANTS.COMMON.ENGLISH_LABEL}
            value={values?.descriptionInEnglish}
            hasError={
              errors?.descriptionInEnglish && dirty?.descriptionInEnglish
            }
            errorMessage={errors?.descriptionInEnglish}
            {...commonTextAreaProps}
          />
          <CustomTextarea
            name="descriptionInFrench"
            label={"Add Description." + STRING_CONSTANTS.COMMON.FRENCH_LABEL}
            value={values?.descriptionInFrench}
            hasError={errors?.descriptionInFrench && dirty?.descriptionInFrench}
            errorMessage={errors?.descriptionInFrench}
            {...commonTextAreaProps}
          />
        </StyledFlex>
        <StyledFlex>
          <CustomInput
            name="titleInEnglish"
            placeholder="Title"
            label={"School Title" + STRING_CONSTANTS.COMMON.ENGLISH_LABEL}
            value={values?.titleInEnglish}
            hasError={errors?.titleInEnglish && dirty?.titleInEnglish}
            errorMessage={errors?.titleInEnglish}
            {...commonInputProps}
          />
          <CustomInput
            name="titleInFrench"
            placeholder="Title"
            label={"School Title" + STRING_CONSTANTS.COMMON.FRENCH_LABEL}
            value={values?.titleInFrench}
            hasError={errors?.titleInFrench && dirty?.titleInFrench}
            errorMessage={errors?.titleInFrench}
            {...commonInputProps}
          />
        </StyledFlex>

        <StyledFlex>
          <CustomDropdown
            placeholder="Select School Category (Cycle)"
            fieldName={"schoolCategory"}
            label={"Select School Category (Cycle)"}
            value={values?.schoolCategory}
            hasError={errors?.schoolCategory && dirty?.schoolCategory}
            errorMessage={errors?.schoolCategory}
            {...commonDropdownProps}
          />
          <CustomDropdown
            fieldName={"grade"}
            label={"Select Grade"}
            placeholder="Select Grade"
            value={values?.grade}
            hasError={errors?.grade && dirty?.grade}
            errorMessage={errors?.grade}
            {...commonDropdownProps}
          />
        </StyledFlex>
        <StyledFlex>
          <CustomDropdown
            fieldName={"schoolStatus"}
            label={"Select School Status"}
            placeholder="Select School Status"
            value={values?.schoolStatus}
            hasError={errors?.schoolStatus && dirty?.schoolStatus}
            errorMessage={errors?.schoolStatus}
            {...commonDropdownProps}
          />
          <CustomDropdown
            fieldName={"type"}
            label={"Select School Education Type"}
            placeholder="Select School Education Type"
            hasError={errors?.type && dirty?.type}
            errorMessage={errors?.type}
            value={values?.type}
            {...commonDropdownProps}
          />
        </StyledFlex>
        <StyledFlex>
          <CustomDropdown
            fieldName={"program"}
            label={"Select Program"}
            placeholder="Select Program"
            value={values?.program}
            {...commonDropdownProps}
          />
        </StyledFlex>
      </FormLayout>
      <CustomConfirmModal
        show={showConfirmationPopup}
        title={STRING_CONSTANTS.COMMON.TITLE_CONFIRMATION_BACK_MESSAGE}
        onCancel={() => setShowConfirmationPopup(false)}
        handleOnConfirm={() => navigate(ROUTE_CONSTANTS.SUBJECT_MASTER)}
      />
    </MainLayout>
  );
};

export default SubjectMasterForm;
