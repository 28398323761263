import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { REDUX_KEYS_ACTIONS } from "constants/common";

export const schoolUserParentAdapter = createEntityAdapter();

export const fetchSchoolUserParentList = createAsyncThunk(
  REDUX_KEYS_ACTIONS.SCHOOL_USER_PARENT.FETCH_SCHOOL_USER_PARENT_LIST,
  async () => {
    const response = await fetch("");
    return response?.json();
  }
);

export const initialSchoolUserParentState = {
  schoolUserParentReduxData: {
    loadingStatus: "not loaded",
    error: null,
    isLoading: false,
  },
};

export const schoolUserParentSlice = createSlice({
  name: REDUX_KEYS_ACTIONS.SCHOOL_USER_PARENT.FEATURE_KEY,
  initialState: initialSchoolUserParentState,
  reducers: {
    add: schoolUserParentAdapter.addOne,
    remove: schoolUserParentAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSchoolUserParentList.pending, (state) => {
      state.schoolUserParentReduxData.isLoading = true;
      state.schoolUserParentReduxData.loadingStatus = "loading";
    });
  },
});

export const schoolUserParentReducer = schoolUserParentSlice.reducer;

export const schoolUserParentActions = schoolUserParentSlice.actions;

export const getArticleManagementState = (rootState) =>
  rootState[REDUX_KEYS_ACTIONS.SCHOOL_USER_PARENT.FEATURE_KEY];
