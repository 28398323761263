import React, { useRef, useState, forwardRef, useEffect } from "react";
import { STRING_CONSTANTS } from "constants/common";
import "./index.css";

const Input = React.forwardRef((props) => {
  let ref = useRef();
  if (forwardRef) {
    ref = forwardRef;
  }
  const {
    name,
    value,
    placeholder,
    onChangeHandler,
    label,
    type,
    icon = null,
    required = false,
    maxLength = null,
    minLength = null,
    mode = STRING_CONSTANTS.COMMON.MODE.ADD,
    classes = "",
    hasError = false,
    isReadOnly = false,
    iconClasses = "",
    labelClass = "",
    disabled = false,
    iconClickHandler,
    errorMessage = "",
  } = props;

  const [fieldIcon, setFieldIcon] = useState(icon);

  useEffect(() => {
    if (icon) {
      setFieldIcon(icon);
    }
  }, [icon]);

  const renderInput = () => {
    if (isReadOnly) {
      return <label className="field-value">{value ? value : "-"}</label>;
    }
    return (
      <input
        ref={ref}
        id={name}
        name={name}
        type={type}
        className={`custom-input-field ${
          mode === STRING_CONSTANTS.COMMON.MODE.VIEW ? "field-view-mode" : ""
        }`}
        value={value}
        placeholder={placeholder}
        onChange={onChangeHandler}
        maxLength={maxLength}
        minLength={minLength}
        readOnly={mode === STRING_CONSTANTS.COMMON.MODE.VIEW}
        disabled={!isReadOnly && disabled}
      />
    );
  };

  return (
    <div className="custom-wrapper">
      <div
        className={`field-group  ${hasError ? "has-error " : ""} ${classes}`}
      >
        <label
          className={`field-group-label ${required ? "required" : ""} ${
            label ? "" : "d-none"
          } `}
        >
          <span>
            {label}
            {!isReadOnly && required ? (
              <span className="required-asterisk">*</span>
            ) : null}
          </span>
        </label>
        {fieldIcon ? (
          <div className={`has-icon ${iconClasses}`}>
            {renderInput()}
            {fieldIcon ? (
              <span className="icon" onClick={iconClickHandler}>
                {fieldIcon}
              </span>
            ) : null}
          </div>
        ) : (
          <div>
            {renderInput()}
            {label ? (
              <label title={label} className={`${labelClass}`}></label>
            ) : null}
          </div>
        )}
        {hasError ? (
          <div className={`error-msg `}>{errorMessage}</div>
        ) : (
          <div className="d-none" />
        )}
      </div>
    </div>
  );
});

export default Input;
