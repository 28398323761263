export const initializeBannerSchema = (languages) => {
  const webSchema = {};
  const mobileSchema = {};

  if (Array.isArray(languages) && languages?.length > 0) {
    languages?.forEach(({ languageCode }) => {
      const createBannerSchema = (schema, prefix) => {
        schema[`${prefix}_${languageCode}`] = {
          value: "",
          error: null,
        };
      };
      createBannerSchema(webSchema, "bannerImageWebsite");
      createBannerSchema(mobileSchema, "bannerImageMobile");
    });
  }

  return { webSchema, mobileSchema };
};
