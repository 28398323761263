import { IconClearSearch } from "assets/images/SVGs";
import { FieldTypes } from "constants/fieldTypes";
import React, { useState, useEffect } from "react";

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

const CustomSearchInput = ({
  value,
  onChange,
  debounce = 300,
  className = "",
  placeholder = "Search...",
  cancelHandler,
  onSearch,
}) => {
  const [inputValue, setInputValue] = useState(value);
  const debouncedSearchTerm = useDebounce(inputValue, debounce);

  useEffect(() => {
    if (debouncedSearchTerm !== value) {
      onChange(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, onChange, value]);

  const handleInputChange = (e) => {
    setInputValue(e?.target?.value);
  };

  const handleKeyPress = (e) => {
    if (e?.key === "Enter" && onSearch) {
      onSearch(inputValue);
    }
  };

  const clearInput = () => {
    setInputValue("");
    if (cancelHandler) cancelHandler();
  };

  return (
    <div className={`search-field ${className}`}>
      <input
        className="custom-input-field"
        type={FieldTypes.TYPE_TEXT}
        value={inputValue}
        onChange={(e) => setInputValue(e?.target?.value)}
        placeholder={placeholder}
        onKeyUp={handleKeyPress}
      />
      {inputValue ? (
        <button
          className="field-clear field-clear-position"
          type="reset"
          onClick={clearInput}
        >
          <IconClearSearch />
        </button>
      ) : null}
    </div>
  );
};

export default CustomSearchInput;
