import React from "react";
import { CustomRadioStyles } from "./customRadioStyles";
import "./radioStyles.css";

export function CustomRadio(props) {
  const {
    title,
    options,
    help = undefined,
    hasError = false,
    errorMessage = "",
    checked = "",
    fieldName,
    onChangeHandler,
    classes = "",
    required = false,
    readOnly = false,
    disabled = false,
    customWiderWidthForIndividualRadio = false,
    requiredCustomWidth = "",
    labelClass = "",
    isReadOnly = false,
  } = props;
  const radioOptions = [...new Set(options)];

  return (
    <CustomRadioStyles.FieldGroup
      className={`field-group ${classes} ${hasError ? "has-error" : ""}`}
      id={fieldName}
    >
      <CustomRadioStyles.StyledLabel
        className={`field-group-label ${required ? "required" : ""}`}
      >
        <span>{title}</span>
      </CustomRadioStyles.StyledLabel>
      {help ? <span className="field-text">{help}</span> : null}
      <CustomRadioStyles.FieldRadioGroup>
        {isReadOnly ? (
          <CustomRadioStyles.PreviewText>
            {checked}
          </CustomRadioStyles.PreviewText>
        ) : radioOptions?.length > 0 ? (
          radioOptions.map((option, index) => (
            <CustomRadioStyles.RadioLabel
              key={fieldName + index}
              htmlFor={`radio${index + 1}`}
              customWiderWidthForIndividualRadio={
                customWiderWidthForIndividualRadio
              }
              requiredCustomWidth={requiredCustomWidth}
            >
              <CustomRadioStyles.RadioInput
                id={option}
                name={fieldName}
                type="radio"
                checked={checked === option}
                onChange={onChangeHandler}
                readOnly={readOnly}
                disabled={disabled}
                className={props?.radioInputClass}
              />
              <CustomRadioStyles.OptionText className={labelClass}>
                {option}
              </CustomRadioStyles.OptionText>
            </CustomRadioStyles.RadioLabel>
          ))
        ) : null}
      </CustomRadioStyles.FieldRadioGroup>
      {hasError && required ? (
        <CustomRadioStyles.ErrorMessage>
          {errorMessage}
        </CustomRadioStyles.ErrorMessage>
      ) : null}
    </CustomRadioStyles.FieldGroup>
  );
}

export default CustomRadio;
