import React from "react";
import CustomButton from "components/CustomButton";
import { useNavigate } from "react-router-dom";
import { STRING_CONSTANTS } from "constants/common";
import "./index.css";

const FormLayout = (props) => {
  const {
    children,
    formHeading,
    handleOnSubmit,
    cancelBtnText = STRING_CONSTANTS.COMMON.BUTTON.CANCEL_TITLE,
    cancelBtnRoute,
    saveBtnText,
    viewButtonText,
    viewFormEditRoute,
    onCancel,
    mode,
  } = props;
  const navigate = useNavigate();
  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    } else {
      navigate(cancelBtnRoute);
    }
  };

  return (
    <div className={"form-layout-main-wrapper"}>
      <div className={"form-layout-header-text"}>
        <div className={"form-layout-inner-wrapper"}>
          <p className={"form-layout-header"}>{formHeading}</p>
        </div>
      </div>
      {children}

      <div className={"form-button-layout"}>
        <CustomButton
          btnText={cancelBtnText}
          onClickHandler={handleCancel}
          btnMarginTop
          width={100}
        />
        {mode === STRING_CONSTANTS.COMMON.MODE.ADD ||
        mode === STRING_CONSTANTS.COMMON.MODE.EDIT ? (
          <CustomButton
            btnText={
              saveBtnText ||
              (mode === STRING_CONSTANTS.COMMON.MODE.ADD
                ? STRING_CONSTANTS.COMMON.BUTTON.SAVE_TITLE
                : STRING_CONSTANTS.COMMON.BUTTON.EDIT_TITLE)
            }
            onClickHandler={handleOnSubmit}
            btnMarginTop
            width={100}
          />
        ) : null}
        {mode === STRING_CONSTANTS.COMMON.MODE.VIEW && viewFormEditRoute ? (
          <CustomButton
            btnText={
              viewButtonText || STRING_CONSTANTS.COMMON.BUTTON.EDIT_TITLE
            }
            onClickHandler={() => navigate(viewFormEditRoute)}
            btnMarginTop
            width={"auto"}
          />
        ) : null}
      </div>
    </div>
  );
};

export default FormLayout;
