import styled from "styled-components";

const FieldGroup = styled.div`
  &.has-error {
    border-color: red;
  }
`;

const StyledLabel = styled.label`
  &.required::after {
    content: "*";
    color: red;
    margin-left: 4px;
  }
`;

const FieldRadioGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const RadioLabel = styled.label`
  width: ${({ customWiderWidthForIndividualRadio, requiredCustomWidth }) =>
    customWiderWidthForIndividualRadio ? `${requiredCustomWidth}%` : "auto"};
`;

const RadioInput = styled.input`
  margin-right: 8px;

  &:checked {
    transform: scale(1.2);
  }

  &:disabled {
    background-color: #f0f0f0;
    cursor: not-allowed;
  }
`;

const OptionText = styled.span`
  color: ${({ style }) => style?.color || ""};
`;

const ErrorMessage = styled.p`
  color: red;
  font-size: 0.875rem;
`;

const PreviewText = styled.label`
  color: grey;
`;

export const CustomRadioStyles = {
  FieldGroup,
  StyledLabel,
  FieldRadioGroup,
  RadioLabel,
  RadioInput,
  OptionText,
  ErrorMessage,
  PreviewText,
};
