import React, { Suspense, lazy, useState } from "react";
import CustomModal from "components/CustomModal";
import RenderTableActionsButton from "components/RenderTableActionsButton";
import {
  REDUX_KEYS_ACTIONS,
  ROUTE_CONSTANTS,
  STATIC_VALUES,
  STRING_CONSTANTS,
} from "constants/common";
import { CustomTable } from "components/CustomTable";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "./index.css";

const MainLayout = lazy(() => import("layouts/MainLayout"));
const RenderArticleManagementFilters = lazy(() =>
  import("./RenderArticleManagementFilters")
);

const ArticleManagement = () => {
  const { articleManagementReduxData } = useSelector(
    (state) => state[REDUX_KEYS_ACTIONS.ARTICLE_MANAGEMENT.FEATURE_KEY]
  );
  const [isFilter, setIsFilter] = useState(false);
  const [switchChecked, setSwitchChecked] = useState(false);
  const defaultModalProps = { isOpen: false, data: null };
  const [modal, setModal] = useState(defaultModalProps);
  const navigate = useNavigate();

  const columns = [
    {
      field: "id",
      header: STATIC_VALUES.COLUMNS.COMMON.ID,
    },
    {
      field: "title",
      header: STATIC_VALUES.COLUMNS.COMMON.TITLE,
      enableSort: true,
    },
    {
      field: "thumbNailImage",
      header: STATIC_VALUES.COLUMNS.COMMON.THUMBNAILIMAGE,
      enableSort: true,
    },
    {
      field: "status",
      header: STATIC_VALUES.COLUMNS.COMMON.STATUS,
      enableSort: true,
    },
    {
      field: "createdAt",
      header: STATIC_VALUES.COLUMNS.COMMON.CREATED_AT,
      enableSort: true,
    },
    {
      field: "updatedAt",
      header: STATIC_VALUES.COLUMNS.COMMON.UPDATED_AT,
      enableSort: true,
    },
    {
      field: "createdBy",
      header: STATIC_VALUES.COLUMNS.COMMON.CREATED_BY,
      enableSort: true,
    },
    {
      field: "updatedBy",
      header: STATIC_VALUES.COLUMNS.COMMON.UPDATED_BY,
      enableSort: true,
    },
    {
      field: "action",
      header: STATIC_VALUES.COLUMNS.COMMON.ACTIONS,
      shouldRenderElement: true,
      renderElement: () => {
        return (
          <RenderTableActionsButton
            setModal={setModal}
            switchChecked={switchChecked}
            handleOnSwitch={handleOnSwitch}
            handleDeleteClick={() => setModal({ data, isOpen: true })}
            showViewIcon={true}
            showEditIcon={true}
            showDeleteIcon={true}
            handleEditRoute={ROUTE_CONSTANTS.EDIT_ARTICLE}
            handleViewRoute={ROUTE_CONSTANTS.VIEW_ARTICLE}
          />
        );
      },
    },
  ];

  const data = [
    {
      id: "1",
      title: "Article one",
      thumbNailImage: "-",
      status: "Active",
      createdAt: "11-oct-2024",
      updatedAt: "11-oct-2024",
      createdBy: "Admin",
      updatedBy: "Admin",
    },
    {
      id: "2",
      title: "Article two",
      thumbNailImage: "-",
      status: "Active",
      createdAt: "11-oct-2024",
      updatedAt: "11-oct-2024",
      createdBy: "Admin",
      updatedBy: "Admin",
    },
    {
      id: "3",
      title: "Article three",
      thumbNailImage: "-",
      status: "Active",
      createdAt: "11-oct-2024",
      updatedAt: "11-oct-2024",
      createdBy: "Admin",
      updatedBy: "Admin",
    },
    {
      id: "4",
      title: "Article one",
      thumbNailImage: "-",
      status: "Active",
      createdAt: "11-oct-2024",
      updatedAt: "11-oct-2024",
      createdBy: "Admin",
      updatedBy: "Admin",
    },
    {
      id: "5",
      title: "Article one",
      thumbNailImage: "-",
      status: "Inactive",
      createdAt: "11-oct-2024",
      updatedAt: "11-oct-2024",
      createdBy: "Admin",
      updatedBy: "Admin",
    },
    {
      id: "6",
      title: "Article lisitng",
      thumbNailImage: "-",
      status: "Inactive",
      createdAt: "11-oct-2024",
      updatedAt: "11-oct-2024",
      createdBy: "Admin",
      updatedBy: "Admin",
    },
    {
      id: "7",
      title: "Article one",
      thumbNailImage: "-",
      status: "Inactive",
      createdAt: "11-oct-2024",
      updatedAt: "11-oct-2024",
      createdBy: "Admin",
      updatedBy: "Admin",
    },
    {
      id: "8",
      title: "Article any",
      thumbNailImage: "-",
      status: "Inactive",
      createdAt: "11-oct-2024",
      updatedAt: "11-oct-2024",
      createdBy: "Admin",
      updatedBy: "Admin",
    },
  ];

  const onChangeData = () => {};

  const multiSelectOptions = [
    { label: "Option 1", value: "option1" },
    { label: "Option 2", value: "option2" },
    { label: "Option 3", value: "option3" },
  ];

  const handleIsFilter = () => {
    setIsFilter(!isFilter);
  };

  const handleOnSwitch = (e, key) => {
    setSwitchChecked(() => e?.target?.checked);
  };

  const handleAddRoute = (params, error) => {
    navigate(ROUTE_CONSTANTS.ADD_ARTICLE);
  };

  return (
    <Suspense>
      <MainLayout>
        {modal?.isOpen ? (
          <CustomModal
            modal={modal?.isOpen}
            setModal={() => setModal(defaultModalProps)}
            modalTitle={"Delete Modal"}
            modalInnerContent={STRING_CONSTANTS.COMMON.RECORD_DELETE_STRING}
            footerBtnText={STRING_CONSTANTS.COMMON.BUTTON.YES_TITLE}
          />
        ) : null}
        <CustomTable
          handleAddRoute={handleAddRoute}
          showAddButton={true}
          addBtnText={STRING_CONSTANTS.COMMON.BUTTON.ADD_TITLE}
          showExportButton={true}
          isFilter={isFilter}
          tableHeader={
            STRING_CONSTANTS.ARTICLE_MANAGEMENT.HEADER_TITLE_ARTICLE_MANAGEMENT
          }
          handleIsFilter={handleIsFilter}
          filterOptions={
            <Suspense>
              <RenderArticleManagementFilters
                showDateFilter={true}
                showMultiSelectFilter={true}
                multiSelectOptions={multiSelectOptions}
              />
            </Suspense>
          }
          data={data}
          columns={columns}
          count={10}
          width={"100"}
          noDataFoundMessage={
            STRING_CONSTANTS.ARTICLE_MANAGEMENT
              .NO_DATA_FOR_ARTICLE_MANAGEMENT_FOUND
          }
          class={"table_class"}
          onChangeData={onChangeData}
          btnMarginTop={false}
        />
      </MainLayout>
    </Suspense>
  );
};

export default ArticleManagement;
