import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { REDUX_KEYS_ACTIONS } from "constants/common";

export const bannerManagementAdapter = createEntityAdapter();

export const fetchBannerList = createAsyncThunk(
  REDUX_KEYS_ACTIONS.BANNER_MANAGEMENT.FETCH_BANNER_LIST,
  async () => {
    const response = await fetch("");
    return response?.json();
  }
);

export const initialBannerManagementState = {
  bannerManagementReduxData: {
    loadingStatus: "not loaded",
    error: null,
    isLoading: false,
  },
};

export const bannerManagementSlice = createSlice({
  name: REDUX_KEYS_ACTIONS.BANNER_MANAGEMENT.FEATURE_KEY,
  initialState: initialBannerManagementState,
  reducers: {
    add: bannerManagementAdapter.addOne,
    remove: bannerManagementAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBannerList.pending, (state) => {
      state.bannerManagementReduxData.isLoading = true;
      state.bannerManagementReduxData.loadingStatus = "loading";
    });
  },
});

export const bannerManagementReducer = bannerManagementSlice.reducer;

export const bannerManagementActions = bannerManagementSlice.actions;

export const getBannerManagementState = (rootState) =>
  rootState[REDUX_KEYS_ACTIONS.BANNER_MANAGEMENT.FEATURE_KEY];
