import React, { useState, Suspense, lazy } from "react";
import { ConfigProvider, Tabs } from "antd";
import { useModal } from "hooks/useModal";
import CustomModal from "components/CustomModal";
import "./tabStyles.css";
import { REDUX_KEYS_ACTIONS } from "constants/common";
import { useSelector } from "react-redux";

const NationListing = lazy(() => import("./NationListing"));
const RegionListing = lazy(() => import("./RegionListing"));
const ProvinceListing = lazy(() => import("./ProvinceListing"));
const CommuneListing = lazy(() => import("./CommuneListing"));
const VillageListing = lazy(() => import("./VillageListing"));

const AddNation = lazy(() => import("./AddNation"));
const AddRegion = lazy(() => import("./AddRegion"));
const AddProvince = lazy(() => import("./AddProvince"));
const AddCommune = lazy(() => import("./AddCommune"));
const AddVillage = lazy(() => import("./AddVillage"));

const MasterManagementTabs = () => {
  const { masterManagementReduxData } = useSelector(
    (state) => state[REDUX_KEYS_ACTIONS.MASTER_MANAGEMENT.FEATURE_KEY]
  );
  const { isModalOpen, openModal, closeModal, modalType } = useModal();
  const [activeTab, setActiveTab] = useState("1");

  const onChange = (key) => {
    setActiveTab(key);
  };

  const renderModalContent = () => {
    switch (modalType) {
      case "1":
        return <AddNation />;
      case "2":
        return <AddRegion />;
      case "3":
        return <AddProvince />;
      case "4":
        return <AddCommune />;
      case "5":
        return <AddVillage />;
      default:
        return null;
    }
  };

  const getModalTitle = (activeTab) => {
    switch (activeTab) {
      case "1":
        return "Nation";
      case "2":
        return "Region";
      case "3":
        return "Province";
      case "4":
        return "Commune";
      case "5":
        return "Village/Sector";
      default:
        return "Unknown";
    }
  };

  const tabContentList = {
    1: {
      title: "Nation",
      component: <NationListing openModal={openModal} />,
      modalContent: <AddNation />,
    },
    2: {
      title: "Region",
      component: <RegionListing openModal={openModal} />,
      modalContent: <AddRegion />,
    },
    3: {
      title: "Province",
      component: <ProvinceListing openModal={openModal} />,
      modalContent: <AddProvince />,
    },
    4: {
      title: "Commune",
      component: <CommuneListing openModal={openModal} />,
      modalContent: <AddCommune />,
    },
    5: {
      title: "Village/Sector",
      component: <VillageListing openModal={openModal} />,
      modalContent: <AddVillage />,
    },
  };

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            itemColor: "red",
            borderRadius: 2,
          },
        }}
      >
        <Tabs defaultActiveKey="1" onChange={onChange}>
          {Object.keys(tabContentList).map((key) => (
            <Tabs.TabPane tab={tabContentList[key]?.title} key={key}>
              <Suspense fallback={<div>Loading...</div>}>
                {tabContentList[key]?.component}
              </Suspense>
            </Tabs.TabPane>
          ))}
        </Tabs>
      </ConfigProvider>

      {isModalOpen(activeTab) ? (
        <Suspense>
          <CustomModal
            modal={isModalOpen(activeTab)}
            setModal={() => closeModal(activeTab)}
            modalTitle={`Add ${getModalTitle(activeTab)}`}
            modalInnerContent={renderModalContent()}
            footerBtnText={"Save"}
          />
        </Suspense>
      ) : null}
    </>
  );
};

export default MasterManagementTabs;
