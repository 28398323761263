import React from "react";
import { List } from "antd";
import { STRING_CONSTANTS } from "constants/common";

const NotificationList = (props) => {
  const { notifications = [] } = props;
  return (
    <div className="notification_list">
      <div className="notification_list_header">
        <div>
          <span className="notification_list_header_span">
            {STRING_CONSTANTS.COMMON.NOTIFICATIONS_LBL_NAME}
          </span>
        </div>
      </div>
      <div className="notification_list_parent">
        <List
          dataSource={notifications}
          renderItem={(item) => (
            <List.Item key={item?.id}>
              <span>{item?.message}</span>
            </List.Item>
          )}
          locale={{ emptyText: STRING_CONSTANTS.COMMON.NO_NOTIFICATION_FOUND }}
        />
      </div>
    </div>
  );
};

export default NotificationList;
