import React from "react";
import AuthLayout from "layouts/AuthLayout";
import { Container } from "styled-bootstrap-grid";
import CustomInput from "components/CustomInput";
import CustomButton from "components/CustomButton";
import { useForm } from "hooks/useForm";
import "layouts/AuthLayout/authStyles.css";
import AuthComponentsHeader from "components/AuthComponentsHeader";
import { useNavigate } from "react-router-dom";
import { STRING_CONSTANTS, ROUTE_CONSTANTS } from "constants/common";

const ForgotPasswordComponent = () => {
  const navigate = useNavigate();
  const initialState = {
    email: {
      value: "",
      error: "",
    },
  };

  const forgotPasswordHandler = (params, error) => {};

  const { handleOnSubmit, handleOnChange } = useForm(
    initialState,
    forgotPasswordHandler
  );

  const onHandleBack = () => {
    navigate(ROUTE_CONSTANTS.HOME);
  };

  return (
    <AuthLayout>
      <Container>
        <div className="auth_wrapper">
          <div className="auth_inner_wrapper">
            <AuthComponentsHeader
              authComponentHeader={
                STRING_CONSTANTS.AUTH.FORGOT_PASSWORD.TITLE_FORGOT_PASSWORD
              }
            />
            <CustomInput
              name="email"
              type="email"
              placeholder="Email"
              onChangeHandler={handleOnChange}
              label="Email"
              required={true}
            />
            <div className="auth-forgot-actions">
              <CustomButton
                btnText={STRING_CONSTANTS.COMMON.BUTTON.RESET_TITLE}
                onClickHandler={handleOnSubmit}
                btnMarginTop
                width={100}
              />
              <CustomButton
                btnText={STRING_CONSTANTS.COMMON.BUTTON.SUBMIT_TITLE}
                onClickHandler={handleOnSubmit}
                btnMarginTop
                width={100}
              />
              <CustomButton
                btnText={STRING_CONSTANTS.COMMON.BUTTON.BACK_TITLE}
                onClickHandler={onHandleBack}
                btnMarginTop
                width={100}
              />
            </div>
          </div>
        </div>
      </Container>
    </AuthLayout>
  );
};

export default ForgotPasswordComponent;
