import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "hooks/useForm";
import AuthLayout from "layouts/AuthLayout";
import { Container } from "styled-bootstrap-grid";
import Input from "components/CustomInput";
import Button from "components/CustomButton";
import AuthComponentsHeader from "components/AuthComponentsHeader";
import CustomCheckbox from "components/CustomCheckbox";
import "layouts/AuthLayout/authStyles.css";
import { IconEyeClose, IconEyeOpen } from "assets/images/SVGs";
import { FieldTypes } from "constants/fieldTypes";
import { STRING_CONSTANTS, ROUTE_CONSTANTS } from "constants/common";

const Login = () => {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const [showPassword, setShowPassword] = useState(true);

  const onhandleCheckboxChange = (event) => {
    setIsChecked(event?.target?.checked);
  };

  const initialState = {
    email: {
      value: "",
      error: "",
    },
    password: {
      value: "",
      error: "",
    },
  };

  const loginHandler = (params, error) => {
    navigate(ROUTE_CONSTANTS.DASHBOARD);
  };

  const { errors, handleOnSubmit, handleOnChange } = useForm(
    initialState,
    loginHandler
  );

  return (
    <AuthLayout>
      <Container>
        <div className="auth_wrapper">
          <div className="auth_inner_wrapper">
            <AuthComponentsHeader
              authComponentHeader={STRING_CONSTANTS.AUTH.LOGIN.LOGIN_SIGNUP}
            />
            <Input
              name="email"
              type={FieldTypes.TYPE_EMAIL}
              placeholder="Email"
              onChangeHandler={handleOnChange}
              label="Email"
              required={true}
            />
            <Input
              name="password"
              type={
                showPassword ? FieldTypes?.TYPE_PASSWORD : FieldTypes?.TYPE_TEXT
              }
              placeholder="Password"
              onChangeHandler={handleOnChange}
              label="Password"
              iconClasses={"auth-pass-icon-eye-view"}
              iconClickHandler={() => setShowPassword(!showPassword)}
              icon={
                showPassword ? (
                  <IconEyeClose color={"#393e46"} />
                ) : (
                  <IconEyeOpen color={"#393e46"} />
                )
              }
              required={true}
            />
            <div className="auth_login_forgot_password_div">
              <span
                className="auth_forgot_password_span"
                onClick={() => navigate("/forgot-password")}
              >
                Forgot Password?
              </span>
            </div>
            <Button
              btnText="Log In"
              onClickHandler={handleOnSubmit}
              btnMarginTop
              width={100}
            />
            <div>
              <CustomCheckbox
                checkboxLabel="Remember Me"
                fieldName="terms"
                onChangeHandler={onhandleCheckboxChange}
                isChecked={isChecked}
              />
            </div>
          </div>
        </div>
      </Container>
    </AuthLayout>
  );
};

export default Login;
