import React from "react";
import { EyeOpenIcon, IconDelete, IconEdit } from "assets/images/SVGs";
import { CustomSwitch } from "components/CustomSwitch";
import { useNavigate } from "react-router-dom";
import { STRING_CONSTANTS } from "constants/common";
import "./index.css";

const RenderTableActionsButton = (props) => {
  const {
    switchChecked,
    handleOnSwitch,
    handleEditRoute,
    showViewIcon,
    handleDeleteClick,
    handleViewRoute,
    showEditIcon,
    showDeleteIcon,
    showEditModal,
    handleEditClick,
  } = props;
  const navigate = useNavigate();

  const onHandleEdit = () => {
    if (showEditModal) {
      handleEditClick();
    } else {
      navigate(handleEditRoute, {
        state: { mode: STRING_CONSTANTS.COMMON.MODE.EDIT },
      });
    }
  };

  return (
    <div className={"action_button_wrapper"}>
      {showEditIcon ? (
        <IconEdit width={23} height={15} onClick={onHandleEdit} />
      ) : null}

      {showViewIcon ? (
        <EyeOpenIcon
          width={20}
          height={15}
          onClick={() =>
            navigate(handleViewRoute, {
              state: { mode: STRING_CONSTANTS.COMMON.MODE.VIEW },
            })
          }
        />
      ) : null}

      {showDeleteIcon ? (
        <IconDelete
          width={20}
          height={15}
          onClick={() => handleDeleteClick()}
        />
      ) : null}

      <CustomSwitch
        fieldName={"isStatus"}
        title={""}
        isChecked={switchChecked}
        onChangeHandler={handleOnSwitch}
      />
    </div>
  );
};

export default RenderTableActionsButton;
