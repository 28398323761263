import React, { Suspense, lazy } from "react";
import MasterManagementTabs from "./MasterManagementTabs";

const MainLayout = lazy(() => import("layouts/MainLayout"));

const MasterManagement = () => {
  return (
    <Suspense>
      <MainLayout>
        <MasterManagementTabs />
      </MainLayout>
    </Suspense>
  );
};

export default MasterManagement;
