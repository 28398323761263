import React, { Suspense, lazy, useState } from "react";
import CustomModal from "components/CustomModal";
import RenderTableActionsButton from "components/RenderTableActionsButton";
import {
  REDUX_KEYS_ACTIONS,
  ROUTE_CONSTANTS,
  STATIC_VALUES,
  STRING_CONSTANTS,
} from "constants/common";
import { CustomTable } from "components/CustomTable";
import { useSelector } from "react-redux";
import "./index.css";

const MainLayout = lazy(() => import("layouts/MainLayout"));
const RenderSchoolUserTeacherFilters = lazy(() =>
  import("./RenderSchoolUserTeacherFilters")
);

const SchoolUserTeacher = () => {
  const { schoolUserTeacherReduxData } = useSelector(
    (state) => state[REDUX_KEYS_ACTIONS.SCHOOL_USER_TEACHER.FEATURE_KEY]
  );

  const [isFilter, setIsFilter] = useState(false);
  const [switchChecked, setSwitchChecked] = useState(false);
  const defaultModalProps = { isOpen: false, data: null };
  const [modal, setModal] = useState(defaultModalProps);

  const columns = [
    {
      field: "employeeId",
      header: STATIC_VALUES.COLUMNS.COMMON.EMPLOYEE_ID,
    },
    {
      field: "userId",
      header: STATIC_VALUES.COLUMNS.COMMON.ID,
    },
    {
      field: "userName",
      header: STATIC_VALUES.COLUMNS.COMMON.USER_NAME,
      enableSort: true,
    },
    {
      field: "gender",
      header: STATIC_VALUES.COLUMNS.COMMON.GENDER,
      enableSort: true,
    },
    {
      field: "schoolName",
      header: STATIC_VALUES.COLUMNS.COMMON.SCHOOL_NAME,
      enableSort: true,
    },
    {
      field: "schoolStatus",
      header: STATIC_VALUES.COLUMNS.COMMON.SCHOOl_STATUS,
      enableSort: true,
    },
    {
      field: "schoolType",
      header: STATIC_VALUES.COLUMNS.COMMON.SCHOOL_TYPE,
      enableSort: true,
    },
    {
      field: "educationType",
      header: STATIC_VALUES.COLUMNS.COMMON.EDUCATION_TYPE,
      enableSort: true,
    },
    {
      field: "program",
      header: STATIC_VALUES.COLUMNS.COMMON.PROGRAM,
      enableSort: true,
    },
    {
      field: "grade",
      header: STATIC_VALUES.COLUMNS.COMMON.GRADE,
      enableSort: true,
    },
    {
      field: "subjects",
      header: STATIC_VALUES.COLUMNS.COMMON.SUBJECTS,
      enableSort: true,
    },
    {
      field: "cycle",
      header:
        STATIC_VALUES.COLUMNS.SCHOOL_USER_STUDENT.SCHOOL_USER_STUDENT_CYCLE,
      enableSort: true,
    },
    {
      field: "highestQualificaton",
      header: STATIC_VALUES.COLUMNS.COMMON.HIGHTEST_QUALIFICATION,
      enableSort: true,
    },
    {
      field: "experience",
      header: STATIC_VALUES.COLUMNS.COMMON.EXPERIENCE,
      enableSort: true,
    },
    {
      field: "emailId",
      header: STATIC_VALUES.COLUMNS.COMMON.EMAIL_ID,
      enableSort: true,
    },
    {
      field: "contactInformation",
      header: STATIC_VALUES.COLUMNS.COMMON.CONATACT_INFORMATION,
      enableSort: true,
    },
    {
      field: "region",
      header: STATIC_VALUES.COLUMNS.COMMON.REGION,
      enableSort: true,
    },
    {
      field: "province",
      header: STATIC_VALUES.COLUMNS.COMMON.PROVINCE,
      enableSort: true,
    },
    {
      field: "commune",
      header: STATIC_VALUES.COLUMNS.COMMON.COMMUNE,
      enableSort: true,
    },
    {
      field: "registeredAt",
      header: STATIC_VALUES.COLUMNS.COMMON.REGISTERED_AT,
      enableSort: true,
    },
    {
      field: "action",
      header: STATIC_VALUES.COLUMNS.COMMON.ACTIONS,
      shouldRenderElement: true,
      renderElement: (value, index, data) => {
        return (
          <RenderTableActionsButton
            setModal={setModal}
            switchChecked={switchChecked}
            handleOnSwitch={handleOnSwitch}
            handleDeleteClick={() => setModal({ data, isOpen: true })}
            showViewIcon={true}
            showDeleteIcon={true}
            handleViewRoute={ROUTE_CONSTANTS.VIEW_SCHOOL_USER_TEACHER}
          />
        );
      },
    },
  ];

  const data = [
    {
      employeeId: "Ed-1",
      userId: "1",
      userName: "Teacher one",
      gender: "Male",
      schoolName: "Saint Mary",
      schoolStatus: "Active",
      schoolType: "Accredited",
      educationType: "Primary",
      program: "First Class",
      grade: "A+",
      subjects: "Science",
      highestQualificaton: "Graduate",
      experience: "3 yr",
      cycle: "Quaterly",
      emailId: "demo@gmail.com",
      contactInformation: "9876098789",
      region: "Region",
      province: "Province",
      commune: "Commune",
      registeredAt: "17-oct-2024",
    },
    {
      employeeId: "Ed-2",
      userId: "2",
      userName: "Teacher Two",
      gender: "Male",
      schoolName: "Vidhya Niketan",
      schoolStatus: "Active",
      schoolType: "Accredited",
      educationType: "Secondary",
      program: "Second Class",
      grade: "B+",
      subjects: "Maths",
      highestQualificaton: "Graduate",
      experience: "3 yr",
      cycle: "Quaterly",
      emailId: "demo@gmail.com",
      contactInformation: "9876098789",
      region: "Region",
      province: "Province",
      commune: "Commune",
      registeredAt: "17-oct-2024",
    },
    {
      employeeId: "Ed-3",
      userId: "3",
      userName: "Teacher Three",
      gender: "Male",
      schoolName: "Saint Prince",
      schoolStatus: "Active",
      schoolType: "Accredited",
      educationType: "Primary",
      program: "First Class",
      grade: "A+",
      subjects: "Science",
      highestQualificaton: "Graduate",
      experience: "3 yr",
      cycle: "Quaterly",
      studentName: "Kane",
      emailId: "demo@gmail.com",
      contactInformation: "9876098789",
      region: "Region",
      province: "Province",
      commune: "Commune",
      registeredAt: "17-oct-2024",
    },
    {
      employeeId: "Ed-4",
      userId: "4",
      userName: "Teacher one",
      gender: "Male",
      schoolName: "Saint Thomas",
      schoolStatus: "Active",
      schoolType: "Accredited",
      educationType: "Primary",
      program: "First Class",
      grade: "A+",
      subjects: "Science",
      cycle: "Quaterly",
      highestQualificaton: "Graduate",
      experience: "3 yr",
      studentName: "Kane",
      emailId: "demo@gmail.com",
      contactInformation: "9876098789",
      region: "Region",
      province: "Province",
      commune: "Commune",
      registeredAt: "17-oct-2024",
    },
    {
      employeeId: "Ed-5",
      userId: "5",
      userName: "Teacher one",
      gender: "Male",
      schoolName: "Saint Annas",
      schoolStatus: "Active",
      schoolType: "Accredited",
      educationType: "Primary",
      program: "First Class",
      grade: "A+",
      subjects: "Science",
      highestQualificaton: "Post Graduate",
      experience: "3 yr",
      cycle: "Quaterly",
      studentName: "Kane",
      emailId: "demo@gmail.com",
      contactInformation: "9876098789",
      region: "Region",
      province: "Province",
      commune: "Commune",
      registeredAt: "17-oct-2024",
    },
    {
      employeeId: "Ed-6",
      userId: "6",
      userName: "Teacher 6",
      gender: "Male",
      schoolName: "Saint Mary",
      schoolStatus: "Active",
      schoolType: "Accredited",
      educationType: "Primary",
      program: "First Class",
      grade: "A+",
      subjects: "Science",
      highestQualificaton: "Post Graduate",
      experience: "3 yr",
      cycle: "Quaterly",
      studentName: "Kane",
      emailId: "demo@gmail.com",
      contactInformation: "9876098789",
      region: "Region",
      province: "Province",
      commune: "Commune",
      registeredAt: "17-oct-2024",
    },
    {
      employeeId: "Ed-7",
      userId: "7",
      userName: "Teacher 7",
      gender: "Male",
      schoolName: "Saint Mary",
      schoolStatus: "Active",
      schoolType: "Accredited",
      educationType: "Primary",
      program: "First Class",
      grade: "A+",
      subjects: "Science",
      highestQualificaton: "Post Graduate",
      experience: "3 yr",
      cycle: "Quaterly",
      studentName: "Kane",
      emailId: "demo@gmail.com",
      contactInformation: "9876098789",
      region: "Region",
      province: "Province",
      commune: "Commune",
      registeredAt: "17-oct-2024",
    },
    {
      employeeId: "Ed-8",
      userId: "8",
      userName: "Teacher eight",
      gender: "Male",
      schoolName: "Saint Mary",
      schoolStatus: "Active",
      schoolType: "Accredited",
      educationType: "Primary",
      program: "First Class",
      grade: "A+",
      subjects: "Science",
      highestQualificaton: "Post Graduate",
      experience: "3 yr",
      cycle: "Quaterly",
      studentName: "Kane",
      emailId: "demo@gmail.com",
      contactInformation: "9876098789",
      region: "Region",
      province: "Province",
      commune: "Commune",
      registeredAt: "17-oct-2024",
    },
  ];

  const onChangeData = () => {};

  const multiSelectOptions = [
    { label: "Option 1", value: "option1" },
    { label: "Option 2", value: "option2" },
    { label: "Option 3", value: "option3" },
  ];

  const handleIsFilter = () => {
    setIsFilter(!isFilter);
  };

  const handleOnSwitch = (e, key) => {
    setSwitchChecked(() => e?.target?.checked);
  };

  return (
    <Suspense>
      <MainLayout>
        {modal?.isOpen ? (
          <CustomModal
            modal={modal?.isOpen}
            setModal={() => setModal(defaultModalProps)}
            modalTitle={"Delete Modal"}
            modalInnerContent={STRING_CONSTANTS.COMMON.RECORD_DELETE_STRING}
            footerBtnText={STRING_CONSTANTS.COMMON.BUTTON.YES_TITLE}
          />
        ) : null}
        <CustomTable
          showExportButton={true}
          isFilter={isFilter}
          tableHeader={
            STRING_CONSTANTS.SCHOOL_USER_TEACHER
              .HEADER_TITLE_SCHOOL_USER_TEACHER
          }
          handleIsFilter={handleIsFilter}
          filterOptions={
            <Suspense>
              <RenderSchoolUserTeacherFilters
                showDateFilter={true}
                showMultiSelectFilter={true}
                multiSelectOptions={multiSelectOptions}
              />
            </Suspense>
          }
          data={data}
          columns={columns}
          count={10}
          width={"100"}
          noDataFoundMessage={
            STRING_CONSTANTS.SCHOOL_USER_TEACHER
              .NO_DATA_FOR_SCHOOL_USER_TEACHER_FOUND
          }
          class={"table_class"}
          onChangeData={onChangeData}
          btnMarginTop={false}
        />
      </MainLayout>
    </Suspense>
  );
};

export default SchoolUserTeacher;
