import React, { useRef } from "react";
import DatePicker from "antd/lib/date-picker";
import dayjs from "dayjs";
import "./index.scss";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Space } from "antd";
dayjs.extend(customParseFormat);

const { RangePicker } = DatePicker;

const CustomDatePicker = React.forwardRef((props, forwardRef) => {
  let ref = useRef();
  if (forwardRef) {
    ref.current = forwardRef;
  }

  const {
    fieldName,
    title,
    placeholder = "dd/mm/yyyy",
    disabledMinDate = "",
    data,
    hasError = false,
    errorMessage = "",
    onChangeHandler,
    restrictFutureDates,
    restrictPastDates,
    isDisabled = false,
    hasIcon = false,
    icon = null,
    required = false,
    className = "",
    disableAutoFocus = false,
    currentDate = new Date(),
    spaceSize = "middle",
    style = {},
    dateFormat,
    isRangeDatePicker = false,
    dateRangeDDClassName = "",
  } = props;

  const disableFutureDate = (date) => date && date > dayjs().endOf("day");
  const disablePastDate = (date) => date && date < dayjs().startOf("day");

  const renderLabel = () => (
    <label
      className={`field-group-label ${required ? "required" : ""} ${
        title ? "" : "d-none"
      }`}
    >
      {title}
      {required ? <span className="required-asterisk">*</span> : null}
    </label>
  );

  const renderError = () =>
    hasError ? <p className="error-msg">{errorMessage}</p> : null;

  const renderIcon = () =>
    hasIcon ? <span className="icon pointer-events-none">{icon}</span> : null;

  const handleDateChange = (date) => {
    const event = {
      target: {
        name: fieldName,
        value: date ? date.format("YYYY-MM-DD") : null,
      },
    };
    onChangeHandler(event);
  };

  const commonDateProps = {
    id: fieldName,
    name: fieldName,
    disabled: isDisabled,
    autoFocus: !disableAutoFocus,
    className,
    style,
    placeholder: isRangeDatePicker ? ["Start date", "End date"] : placeholder,
    disabledDate: restrictFutureDates
      ? disableFutureDate
      : restrictPastDates
      ? disablePastDate
      : undefined,
  };

  return (
    <div className={`field-group  ${hasError ? "has-error" : ""}`}>
      {renderLabel()}
      <Space direction="vertical" size={spaceSize}>
        <div className={`${hasIcon ? "has-icon" : ""} ${className} `}>
          {isRangeDatePicker ? (
            <RangePicker
              allowClear={true}
              defaultValue={dayjs()}
              value={props?.data !== "" ? props?.data : ""}
              onChange={(val) => onChangeHandler(val, fieldName)}
              dropdownClassName={dateRangeDDClassName}
              {...commonDateProps}
            />
          ) : (
            <DatePicker
              ref={ref}
              id={fieldName}
              name={fieldName}
              onChange={handleDateChange}
              value={data ? dayjs(data) : null}
              format={dateFormat ? dateFormat : "YYYY-MM-DD"}
              minDate={restrictPastDates ? disabledMinDate : null}
              maxDate={restrictFutureDates ? currentDate : null}
              {...commonDateProps}
            />
          )}

          {renderIcon()}
        </div>
      </Space>
      {renderError()}
    </div>
  );
});

export default CustomDatePicker;
