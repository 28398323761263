import React from "react";
import { Select } from "antd";
import "./index.css";
import { STRING_CONSTANTS } from "constants/common";

const { Option } = Select;

const Dropdown = ({
  options = [],
  label,
  placeholder = "Please select",
  onChangeHandler,
  value,
  mode = STRING_CONSTANTS.COMMON.MODE.ADD,
  allowClear = true,
  defaultValue,
  fieldName,
  required = false,
  isReadOnly = false,
  disableAutoFocus = false,
  hasError = false,
  errorMessage = "",
  classes = "",
  ...restProps
}) => {
  return (
    <div
      className={`custom-wrapper dropdown-wrapper-container field-group ${classes}`}
    >
      <label className={`field-group-label ${required && "required"} `}>
        <span className="label-dropdown-span">
          {label}
          {!isReadOnly ? (
            required ? (
              <span className="required-asterisk">*</span>
            ) : null
          ) : null}
        </span>
      </label>
      <Select
        className={`custom-select ${
          mode === STRING_CONSTANTS.COMMON.MODE.VIEW ? "field-view-mode" : ""
        }`}
        placeholder={placeholder}
        onChange={(selectedValue, option) => {
          onChangeHandler({
            target: {
              value: selectedValue,
              name: fieldName,
              option: option,
            },
          });
        }}
        value={value}
        mode={mode}
        allowClear={allowClear}
        disabled={mode === STRING_CONSTANTS.COMMON.MODE.VIEW}
        defaultValue={defaultValue}
        autoFocus={!disableAutoFocus}
        status={hasError ? "error" : ""}
        {...restProps}
      >
        {options.map((option) => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </Select>

      {hasError ? (
        <div className="error-msg ">{errorMessage}</div>
      ) : (
        <div className="d-none"></div>
      )}
    </div>
  );
};

export default Dropdown;
