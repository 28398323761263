import * as firebase from "firebase/app";
import { STRING_CONSTANTS } from "constants/common";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCVFDxx7gr3LJvYuY0qpxlzqQYuqySBC5I",
  authDomain: "edulac-e7711.firebaseapp.com",
  projectId: "edulac-e7711",
  storageBucket: "edulac-e7711.appspot.com",
  messagingSenderId: "297541074260",
  appId: "1:297541074260:web:e9a6a8e7cc98ac4d7e5cc1",
  measurementId: "G-RTFJ8GDSPH",
};

export const requestFirebaseNotificationPermission = async () => {
  firebase.initializeApp(firebaseConfig);

  try {
    const status = await Notification.requestPermission();
    if (status === STRING_CONSTANTS.COMMON.PERMISSION.GRANTED) {
      const messaging = getMessaging();
      const fcm_token = await getToken(messaging, {
        vapidKey: `${process.env.VAPID_KEY}`,
      });
      if (fcm_token) {
        return fcm_token;
      }
    } else return "";
  } catch (e) {
    console.log("requestFirebaseNotificationPermission  e", e);
    return "";
  }
};

export function readMessages(callback) {
  try {
    const messaging = getMessaging();
    onMessage(messaging, function (payload) {
      const notification = payload && payload?.notification;
      const title = notification && notification?.title;
      const body = notification && notification?.body;

      if (title && body) {
        callback(title, body);
      }
    });
  } catch (e) {
    console.log("readFirebaseNotification e", e);
  }
}
