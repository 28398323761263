import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { REDUX_KEYS_ACTIONS } from "constants/common";

export const articleManagementAdapter = createEntityAdapter();

export const fetchArticles = createAsyncThunk(
  REDUX_KEYS_ACTIONS.ARTICLE_MANAGEMENT.FETCH_ARTICLES,
  async () => {
    const response = await fetch("");
    return response?.json();
  }
);

export const initialArticleManagementState = {
  articleManagementReduxData: {
    loadingStatus: "not loaded",
    error: null,
    isLoading: false,
  },
};

export const articleManagementSlice = createSlice({
  name: REDUX_KEYS_ACTIONS.ARTICLE_MANAGEMENT.FEATURE_KEY,
  initialState: initialArticleManagementState,
  reducers: {
    add: articleManagementAdapter.addOne,
    remove: articleManagementAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchArticles.pending, (state) => {
      state.articleManagementReduxData.isLoading = true;
      state.articleManagementReduxData.loadingStatus = "loading";
    });
  },
});

export const articleManagementReducer = articleManagementSlice.reducer;

export const articleManagementActions = articleManagementSlice.actions;

export const getArticleManagementState = (rootState) =>
  rootState[REDUX_KEYS_ACTIONS.ARTICLE_MANAGEMENT.FEATURE_KEY];
