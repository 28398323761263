import React, { useState, Suspense, lazy } from "react";
import CustomModal from "components/CustomModal";
import { CustomTable } from "components/CustomTable";
import RenderTableActionsButton from "components/RenderTableActionsButton";
import {
  STRING_CONSTANTS,
  ROUTE_CONSTANTS,
  STATIC_VALUES,
  REDUX_KEYS_ACTIONS,
} from "constants/common";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "./index.css";

const MainLayout = lazy(() => import("layouts/MainLayout"));
const RenderSchoolFacilitiesMasterFilters = lazy(() =>
  import("./RenderSchoolFacilitiesMasterFilters")
);

const SchoolFacilitiesMaster = () => {
  const { schoolFacilitiesMasterReduxData } = useSelector(
    (state) => state[REDUX_KEYS_ACTIONS.SCHOOL_FACILITIES_MASTER.FEATURE_KEY]
  );
  const navigate = useNavigate();
  const defaultModalProps = { isOpen: false, data: null };
  const [isFilter, setIsFilter] = useState(false);
  const [switchChecked, setSwitchChecked] = useState(false);
  const [modal, setModal] = useState(defaultModalProps);

  const columns = [
    {
      field: "id",
      header: STATIC_VALUES.COLUMNS.COMMON.ID,
      customHeaderWidth: "10",
    },
    {
      field: "schoolsFacilityName",
      header: STATIC_VALUES.COLUMNS.COMMON.SCHOOLS,
      enableSort: true,
      customHeaderWidth: "10",
    },
    {
      field: "description",
      header: STATIC_VALUES.COLUMNS.SCHOOL_FACILITIES_MASTER.DESCRIPTION,
      enableSort: true,
      customHeaderWidth: "15",
    },
    {
      field: "category",
      header: STATIC_VALUES.COLUMNS.COMMON.CATEGORY,
      enableSort: true,
      customHeaderWidth: "12",
    },
    {
      field: "status",
      header: STATIC_VALUES.COLUMNS.COMMON.STATUS,
      enableSort: true,
      customHeaderWidth: "10",
    },

    {
      field: "createdDate",
      header: STATIC_VALUES.COLUMNS.COMMON.CREATED_DATE,
      enableSort: true,
    },
    {
      field: "updatedDate",
      header: STATIC_VALUES.COLUMNS.COMMON.UPDATED_DATE,
      enableSort: true,
    },
    {
      field: "action",
      header: STATIC_VALUES.COLUMNS.COMMON.ACTIONS,
      shouldRenderElement: true,
      renderElement: () => {
        return (
          <RenderTableActionsButton
            showViewIcon
            showEditIcon
            showDeleteIcon
            setModal={setModal}
            switchChecked={switchChecked}
            handleOnSwitch={handleOnSwitch}
            handleDeleteClick={() => setModal({ data, isOpen: true })}
            handleEditRoute={ROUTE_CONSTANTS.EDIT_SCHOOL_FACILITIES}
            handleViewRoute={ROUTE_CONSTANTS.VIEW_SCHOOL_FACILITIES}
          />
        );
      },
    },
  ];

  const data = [
    {
      id: "1",
      schoolsFacilityName: "John Brown",
      category: "Category",
      status: "Active",
      description: "Loreum ipsum data",
      educationType: "formal",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
    },
    {
      id: "2",
      schoolsFacilityName: "Jim Green",
      category: "Category",
      status: "Active",
      description: "Loreum ipsum data",
      educationType: "formal",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
    },
    {
      id: "3",
      schoolsFacilityName: "Joe Black",
      category: "Category",
      status: "Active",
      description: "Loreum ipsum data",
      educationType: "formal",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
    },
    {
      id: "4",
      schoolsFacilityName: "Jim Green",
      category: "Category",
      status: "Inactive",
      description: "Loreum ipsum data",
      educationType: "formal",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
    },
    {
      id: "5",
      schoolsFacilityName: "Joe Black",
      category: "Category",
      status: "Inactive",
      description: "Loreum ipsum data",
      educationType: "formal",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
    },
    {
      id: "6",
      schoolsFacilityName: "Jim Green",
      category: "Category",
      status: "Active",
      description: "Loreum ipsum data",
      educationType: "formal",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
    },
    {
      id: "7",
      schoolsFacilityName: "Joe Black",
      category: "Category",
      status: "Inactive",
      description: "Loreum ipsum data",
      educationType: "formal",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
    },
    {
      id: "8",
      schoolsFacilityName: "Jim Green",
      category: "Category",
      status: "Active",
      description: "Loreum ipsum data",
      educationType: "formal",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
    },
  ];

  const onChangeData = () => {};

  const multiSelectOptions = [
    { label: "Option 1", value: "option1" },
    { label: "Option 2", value: "option2" },
    { label: "Option 3", value: "option3" },
  ];

  const handleIsFilter = () => {
    setIsFilter(!isFilter);
  };

  const handleAddRoute = (params, error) => {
    navigate(ROUTE_CONSTANTS.ADD_SCHOOL_FACILITIES);
  };

  const handleOnSwitch = (e, key) => {
    setSwitchChecked(() => e?.target?.checked);
  };

  return (
    <Suspense>
      <MainLayout>
        {modal?.isOpen ? (
          <CustomModal
            modal={modal?.isOpen}
            setModal={() => setModal(defaultModalProps)}
            modalTitle={"Delete Modal"}
            modalInnerContent={STRING_CONSTANTS.COMMON.RECORD_DELETE_STRING}
            footerBtnText={"Yes"}
          />
        ) : null}

        <CustomTable
          showAddButton={true}
          handleAddRoute={handleAddRoute}
          isFilter={isFilter}
          addBtnText={"Add"}
          tableHeader={"School Facilities Master"}
          handleIsFilter={handleIsFilter}
          filterOptions={
            <Suspense>
              <RenderSchoolFacilitiesMasterFilters
                showDateFilter={true}
                showMultiSelectFilter={true}
                multiSelectOptions={multiSelectOptions}
              />
            </Suspense>
          }
          data={data}
          columns={columns}
          count={10}
          width={"100"}
          noDataFoundMessage={
            STRING_CONSTANTS.SCHOOL_FACILITY_MASTER
              .NO_DATA_FOR_SCHOOL_FACILITIES_FOUND
          }
          class={"table_class"}
          onChangeData={onChangeData}
          btnMarginTop={false}
        />
      </MainLayout>
    </Suspense>
  );
};

export default SchoolFacilitiesMaster;
