import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { REDUX_KEYS_ACTIONS } from "constants/common";

export const schoolTypeMasterAdapter = createEntityAdapter();

export const fetchSchoolTypeList = createAsyncThunk(
  REDUX_KEYS_ACTIONS.SCHOOL_TYPE_MASTER.FETCH_SCHOOL_TYPE_MASTER_LIST,
  async () => {
    const response = await fetch("");
    return response?.json();
  }
);

export const initialSchoolTypeMasterState = {
  schoolTypeMasterReduxData: {
    loadingStatus: "not loaded",
    error: null,
    isLoading: false,
  },
};

export const schoolTypeMasterSlice = createSlice({
  name: REDUX_KEYS_ACTIONS.SCHOOL_TYPE_MASTER.FEATURE_KEY,
  initialState: initialSchoolTypeMasterState,
  reducers: {
    add: schoolTypeMasterAdapter.addOne,
    remove: schoolTypeMasterAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSchoolTypeList.pending, (state) => {
      state.schoolTypeMasterReduxData.isLoading = true;
      state.schoolTypeMasterReduxData.loadingStatus = "loading";
    });
  },
});

export const schoolTypeMasterReducer = schoolTypeMasterSlice.reducer;

export const schoolTypeMasterActions = schoolTypeMasterSlice.actions;

export const getSchoolTypeMasterState = (rootState) =>
  rootState[REDUX_KEYS_ACTIONS.SCHOOL_TYPE_MASTER.FEATURE_KEY];
