import React from "react";
import { Modal } from "antd";
import Button from "components/CustomButton";
import "./modalStyles.css";

const CustomModal = ({
  modal,
  setModal,
  modalTitle,
  modalInnerContent,
  footerBtnText,
  handleOnConfirm,
}) => {
  const confirmHandler = () => {
    if (handleOnConfirm) {
      handleOnConfirm();
    } else {
      setModal(false);
    }
  };

  return (
    <Modal
      title={modalTitle}
      centered
      open={modal}
      onOk={() => handleOnConfirm()}
      onCancel={() => setModal(false)}
      footer={[
        <div className="modal_footer_wrapper">
          <Button btnText={"Cancel"} onClickHandler={() => setModal(false)} />
          <Button btnText={footerBtnText} onClickHandler={confirmHandler} />
        </div>,
      ]}
    >
      <p className="modal_inner_content">{modalInnerContent}</p>
    </Modal>
  );
};

export default CustomModal;
