import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MainLayout from "layouts/MainLayout";
import CustomInput from "components/CustomInput";
import { useForm } from "hooks/useForm";
import CustomTextarea from "components/CustomTextarea";
import FormLayout from "layouts/FormLayout";
import { StyledFlex } from "constants/commonStyles";
import { STRING_CONSTANTS, ROUTE_CONSTANTS } from "constants/common";
import { FieldTypes } from "constants/fieldTypes";
import CustomConfirmModal from "components/CustomConfirmModal";
import useDocumentTitle from "hooks/useDocumentTitle";
import "./index.css";

const PlatformUserTypeMasterForm = (props) => {
  const { formHeading, mode } = props;
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [initialReqData, setInitialReqData] = useState({});
  const navigate = useNavigate();
  const PageTitle =
    mode === STRING_CONSTANTS.COMMON.MODE.ADD
      ? STRING_CONSTANTS.PLAFORM_USER_TYPE_MASTER.ADD_PLATFORM_USER_TYPE_MASTER
      : mode === STRING_CONSTANTS.COMMON.MODE.VIEW
      ? STRING_CONSTANTS.PLAFORM_USER_TYPE_MASTER.VIEW_PLATFORM_USER_TYPE_MASTER
      : STRING_CONSTANTS.PLAFORM_USER_TYPE_MASTER
          .EDIT_PLATFORM_USER_TYPE_MASTER;
  useDocumentTitle(`${STRING_CONSTANTS.COMMON.EDULAC_TITLE}-${PageTitle}`);

  const initialState = {
    userTypeInEnglish: {
      value: "",
      error: "",
      required: true,
    },
    userTypeInFrench: {
      value: "",
      error: "",
      required: true,
    },
    nameInEnglish: {
      value: "",
      error: "",
      required: true,
    },
    nameInFrench: {
      value: "",
      error: "",
      required: true,
    },
    descriptionInEnglish: {
      value: "",
      error: "",
      required: true,
    },
    descriptionInFrench: {
      value: "",
      error: "",
      required: true,
    },
  };

  const onSubmitPlatformUserTypeMaster = (params, error) => {};

  const {
    errors,
    dirty,
    values,
    handleOnSubmit,
    handleOnChange,
    clearStateSchema,
  } = useForm(initialState, onSubmitPlatformUserTypeMaster);

  useEffect(() => {
    setInitialReqData(values);
  }, [values]);

  const commonInputProps = {
    type: FieldTypes.TYPE_TEXT,
    onChangeHandler: handleOnChange,
    mode: mode,
    required: true,
  };

  const commonTextAreaProps = {
    type: FieldTypes.TYPE_TEXT,
    placeholder: "Add Description.",
    onChangeHandler: handleOnChange,
    mode: mode,
    textareaClass: "platofrm-user-type-master-textarea-content",
    required: true,
    rows: "1",
    cols: "2",
  };

  const onCancelClickHandler = () => {
    const formDirty = Object.keys(dirty)?.some((key) => dirty[key]);
    if (
      !formDirty &&
      JSON.stringify(initialReqData) === JSON.stringify(values)
    ) {
      clearStateSchema();
      navigate(ROUTE_CONSTANTS.PLATFORM_USER_TYPE_MASTER);
    } else {
      setShowConfirmationPopup(true);
    }
  };

  return (
    <MainLayout>
      <FormLayout
        formHeading={formHeading}
        handleOnSubmit={handleOnSubmit}
        cancelBtnText={STRING_CONSTANTS.COMMON.BUTTON.CANCEL_TITLE}
        cancelBtnRoute={ROUTE_CONSTANTS.PLATFORM_USER_TYPE_MASTER}
        saveBtnText={
          mode === STRING_CONSTANTS.COMMON.MODE.ADD
            ? STRING_CONSTANTS.COMMON.BUTTON.SAVE_TITLE
            : STRING_CONSTANTS.COMMON.BUTTON.UPDATE_TITLE
        }
        mode={mode}
        viewButtonText={STRING_CONSTANTS.COMMON.BUTTON.EDIT_TITLE}
        viewFormEditRoute={ROUTE_CONSTANTS.EDIT_PLATFORM_USER_TYPE_MASTER}
        onCancel={onCancelClickHandler}
      >
        <StyledFlex>
          <CustomInput
            name="userTypeInEnglish"
            placeholder="User Type"
            label={"User Type" + STRING_CONSTANTS.COMMON.ENGLISH_LABEL}
            value={values?.userTypeInEnglish}
            hasError={errors?.userTypeInEnglish && dirty?.userTypeInEnglish}
            errorMessage={errors?.userTypeInEnglish}
            {...commonInputProps}
          />
          <CustomInput
            name="userTypeInFrench"
            placeholder="User Type"
            label={"User Type" + STRING_CONSTANTS.COMMON.FRENCH_LABEL}
            value={values?.userTypeInFrench}
            hasError={errors?.userTypeInFrench && dirty?.userTypeInFrench}
            errorMessage={errors?.userTypeInFrench}
            {...commonInputProps}
          />
        </StyledFlex>
        <StyledFlex>
          <CustomInput
            name="nameInEnglish"
            placeholder="Name"
            label={"Name" + STRING_CONSTANTS.COMMON.ENGLISH_LABEL}
            value={values?.nameInEnglish}
            hasError={errors?.nameInEnglish && dirty?.nameInEnglish}
            errorMessage={errors?.nameInEnglish}
            {...commonInputProps}
          />
          <CustomInput
            name="nameInFrench"
            placeholder="Name"
            label={"Name" + STRING_CONSTANTS.COMMON.FRENCH_LABEL}
            value={values?.nameInFrench}
            hasError={errors?.nameInFrench && dirty?.nameInFrench}
            errorMessage={errors?.nameInFrench}
            {...commonInputProps}
          />
        </StyledFlex>
        <StyledFlex>
          <CustomTextarea
            name="descriptionInEnglish"
            label={"Add Description." + STRING_CONSTANTS.COMMON.ENGLISH_LABEL}
            value={values?.descriptionInEnglish}
            hasError={
              errors?.descriptionInEnglish && dirty?.descriptionInEnglish
            }
            errorMessage={errors?.descriptionInEnglish}
            {...commonTextAreaProps}
          />
          <CustomTextarea
            name="descriptionInFrench"
            label={"Add Description." + STRING_CONSTANTS.COMMON.FRENCH_LABEL}
            value={values?.descriptionInFrench}
            hasError={errors?.descriptionInFrench && dirty?.descriptionInFrench}
            errorMessage={errors?.descriptionInFrench}
            {...commonTextAreaProps}
          />
        </StyledFlex>
      </FormLayout>
      <CustomConfirmModal
        show={showConfirmationPopup}
        title={STRING_CONSTANTS.COMMON.TITLE_CONFIRMATION_BACK_MESSAGE}
        onCancel={() => setShowConfirmationPopup(false)}
        handleOnConfirm={() =>
          navigate(ROUTE_CONSTANTS.PLATFORM_USER_TYPE_MASTER)
        }
        primaryBtnName={STRING_CONSTANTS.COMMON.BUTTON.YES_TITLE}
        secondaryBtnName={STRING_CONSTANTS.COMMON.BUTTON.NO_TITLE}
      />
    </MainLayout>
  );
};

export default PlatformUserTypeMasterForm;
