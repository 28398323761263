import styled from "styled-components";

const MainLayoutLeftPanel = styled.div`
  width: ${({ isCollapsed }) => (isCollapsed ? "6%" : "15%")};
  height: 100vh;
`;

const MainLayoutRightPanel = styled.div`
  width: ${({ isCollapsed }) => (isCollapsed ? "94%" : "85%")};
  background-color: #f7f7f7;
  height: 100vh;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #f7f7f7;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-track {
    background-color: #393e4614;
    border-radius: 3px;
  }
`;

export { MainLayoutLeftPanel, MainLayoutRightPanel };
