import React from "react";
import "./index.css";

const Button = (props) => {
  const {
    btnText,
    onClickHandler,
    btnMarginTop,
    width,
    title,
    size = "small",
    className = "",
    shouldDisable = false,
  } = props;
  const widthClass = width ? `w-${width}` : "";
  const marginTopClass = btnMarginTop ? "mt-1" : "";

  let btnSize = "btn--sm";
  if (size === "medium") btnSize = "btn--md";

  return (
    <div className="d-block">
      <button
        className={`btn ${widthClass} ${marginTopClass} ${
          btnSize ? btnSize : " "
        }${className} ${shouldDisable ? " disabled" : " "} `}
        onClick={() => onClickHandler()}
        title={title}
      >
        {btnText}
      </button>
    </div>
  );
};

export default Button;
