import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { REDUX_KEYS_ACTIONS } from "constants/common";

export const schoolFacilitiesMasterAdapter = createEntityAdapter();

export const fetchSchoolFacilitiesList = createAsyncThunk(
  REDUX_KEYS_ACTIONS.SCHOOL_FACILITIES_MASTER
    .FETCH_SCHOOL_FACILITIES_MASTER_LIST,
  async () => {
    const response = await fetch("");
    return response?.json();
  }
);

export const initialSchoolFacilitiesMasterState = {
  schoolFacilitiesMasterReduxData: {
    loadingStatus: "not loaded",
    error: null,
    isLoading: false,
  },
};

export const schoolFacilitiesMasterSlice = createSlice({
  name: REDUX_KEYS_ACTIONS.SCHOOL_FACILITIES_MASTER.FEATURE_KEY,
  initialState: initialSchoolFacilitiesMasterState,
  reducers: {
    add: schoolFacilitiesMasterAdapter.addOne,
    remove: schoolFacilitiesMasterAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSchoolFacilitiesList.pending, (state) => {
      state.schoolFacilitiesMasterReduxData.isLoading = true;
      state.schoolFacilitiesMasterReduxData.loadingStatus = "loading";
    });
  },
});

export const schoolFacilitiesMasterReducer =
  schoolFacilitiesMasterSlice.reducer;

export const schoolFacilitiesMasterActions =
  schoolFacilitiesMasterSlice.actions;

export const getSchoolFacilitiesMasterState = (rootState) =>
  rootState[REDUX_KEYS_ACTIONS.SCHOOL_FACILITIES_MASTER.FEATURE_KEY];
