import React from "react";
import "./index.css";

const CustomCheckbox = (props) => {
  const {
    fieldName,
    onChangeHandler,
    required = false,
    readOnly = false,
    disabled = false,
    checkboxLabel,
    isChecked = false,
    customClassName = "",
  } = props;
  return (
    <div className={`${"checkbox_wrapper"} ${customClassName}`}>
      <input
        className={"checkbox_styles"}
        id={fieldName}
        name={fieldName}
        onChange={onChangeHandler}
        readOnly={readOnly}
        disabled={disabled}
        type="checkbox"
        checked={isChecked}
        required={required}
      />
      <label className={"checkboxLabel_styles"} htmlFor={fieldName}>
        {checkboxLabel}
        {required ? <span className={"required-asterik"}>*</span> : null}
      </label>
    </div>
  );
};

export default CustomCheckbox;
