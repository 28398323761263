import React from "react";
import MainLayout from "layouts/MainLayout";
import FormLayout from "layouts/FormLayout";
import { STRING_CONSTANTS, ROUTE_CONSTANTS } from "constants/common";
import { useForm } from "hooks/useForm";
import { FieldTypes } from "constants/fieldTypes";
import { StyledFlex } from "constants/commonStyles";
import CustomInput from "components/CustomInput";
import CustomDropdown from "components/CustomDropdown";
import useDocumentTitle from "hooks/useDocumentTitle";
import "./index.css";

const SchoolUserTeacherForm = (props) => {
  const { formHeading, mode } = props;
  const PageTitle =
    mode === STRING_CONSTANTS.COMMON.MODE.ADD
      ? STRING_CONSTANTS.SCHOOL_USER_TEACHER
          .HEADER_TITLE_ADD_SCHOOL_USER_TEACHER
      : mode === STRING_CONSTANTS.COMMON.MODE.VIEW
      ? STRING_CONSTANTS.SCHOOL_USER_TEACHER
          .HEADER_TITLE_VIEW_SCHOOL_USER_TEACHER
      : STRING_CONSTANTS.SCHOOL_USER_TEACHER
          .HEADER_TITLE_EDIT_SCHOOL_USER_TEACHER;
  useDocumentTitle(`${STRING_CONSTANTS.COMMON.EDULAC_TITLE}-${PageTitle}`);

  const initialState = {
    employeeId: {
      value: "Ed-1",
      error: "",
    },
    userName: {
      value: "Anna Maria",
      error: "",
    },
    gender: {
      value: "Femal",
      error: "",
    },
    schoolName: {
      value: "Saint Marys",
      error: "",
    },
    educationType: {
      value: "Secondary",
      error: "",
    },
    schoolStatus: {
      value: "Active",
      error: "",
    },
    schoolType: {
      value: "Secondary",
      error: "",
    },
    grade: {
      value: "A+",
      error: "",
    },
    program: {
      value: "Second Class",
      error: "",
    },
    subjects: {
      value: "Science",
      error: "",
    },
    cycle: {
      value: "Cycle",
      error: "",
    },
    highestQualification: {
      value: "Graduate",
      error: "",
    },
    experience: {
      value: "3 Yr",
      error: "",
    },
    studentName: {
      value: "Kane Will",
      error: "",
    },
    emailId: {
      value: "demo@gmail.com",
      error: "",
    },
    contactInformation: {
      value: "9800067890",
      error: "",
    },
    region: {
      value: "Region",
      error: "",
    },
    province: {
      value: "Province",
      error: "",
    },
    commune: {
      value: "Commune",
      error: "",
    },
  };

  const onHandleSchoolUserStudent = (params, error) => {
    if (mode) {
    } else {
    }
  };

  const {
    values,
    errors,
    dirty,
    handleOnSubmit,
    handleOnChange,
    handleOnSelection,
  } = useForm(initialState, onHandleSchoolUserStudent);

  const options = [
    { value: "option1", label: "Option 1" },
    { value: "option2", label: "Option 2" },
    { value: "option3", label: "Option 3" },
  ];

  const commonInputProps = {
    type: FieldTypes.TYPE_TEXT,
    onChangeHandler: handleOnChange,
    mode: mode,
    required: true,
  };

  const commonDropdownProps = {
    options: options,
    onChangeHandler: handleOnSelection,
    allowClear: true,
    mode: mode,
    required: true,
  };

  return (
    <MainLayout>
      <FormLayout
        handleOnSubmit={handleOnSubmit}
        formHeading={formHeading}
        cancelBtnText={STRING_CONSTANTS.COMMON.BUTTON.CANCEL_TITLE}
        cancelBtnRoute={ROUTE_CONSTANTS.SCHOOL_USER_STUDENT}
        saveBtnText={STRING_CONSTANTS.COMMON.BUTTON.SAVE_TITLE}
        mode={mode}
        viewButtonText={STRING_CONSTANTS.COMMON.BUTTON.EDIT_TITLE}
      >
        <StyledFlex>
          <CustomInput
            name="userName"
            placeholder="User Name"
            label={"User Name"}
            value={values?.userName}
            {...commonInputProps}
          />
          <CustomInput
            name="emailId"
            placeholder="Email Id"
            label={"Email Id"}
            value={values?.emailId}
            {...commonInputProps}
          />
        </StyledFlex>
        <StyledFlex>
          <CustomInput
            name="contactInformation"
            placeholder="Contact Information"
            label={"Contact Information"}
            value={values?.contactInformation}
            {...commonInputProps}
          />
          <CustomInput
            name="employeeId"
            placeholder="Employee Id"
            label={"Employee Id"}
            value={values?.employeeId}
            {...commonInputProps}
          />
        </StyledFlex>
        <StyledFlex>
          <CustomDropdown
            placeholder="Select School Name"
            fieldName={"schoolName"}
            label={"School Name"}
            value={values?.schoolName}
            {...commonDropdownProps}
          />
          <CustomDropdown
            placeholder="Select Education Type"
            fieldName={"educationType"}
            label={"Education Type"}
            value={values?.educationType}
            {...commonDropdownProps}
          />
        </StyledFlex>
        <StyledFlex>
          <CustomDropdown
            placeholder="Select School Status"
            fieldName={"schoolStatus"}
            label={"School Status"}
            value={values?.schoolStatus}
            {...commonDropdownProps}
          />
          <CustomDropdown
            placeholder="Select School Type"
            fieldName={"schoolType"}
            label={"School Type"}
            value={values?.schoolType}
            {...commonDropdownProps}
          />
        </StyledFlex>
        <StyledFlex>
          <CustomDropdown
            placeholder="Select Program"
            fieldName={"program"}
            label={"Program"}
            value={values?.program}
            {...commonDropdownProps}
          />
          <CustomDropdown
            placeholder="Select Gender"
            fieldName={"gender"}
            label={"Gender"}
            value={values?.gender}
            {...commonDropdownProps}
          />
        </StyledFlex>
        <StyledFlex>
          <CustomDropdown
            placeholder="Select Subjects"
            fieldName={"subjects"}
            label={"Subjects"}
            value={values?.subjects}
            {...commonDropdownProps}
          />
          <CustomDropdown
            placeholder="Select Grade"
            fieldName={"grade"}
            label={"Grade"}
            value={values?.grade}
            {...commonDropdownProps}
          />
        </StyledFlex>
        <StyledFlex>
          <CustomDropdown
            placeholder="Select User Cycle"
            fieldName={"cycle"}
            label={"User Cycle"}
            value={values?.cycle}
            {...commonDropdownProps}
          />
          <CustomDropdown
            placeholder="Select Region"
            fieldName={"region"}
            label={"Region"}
            value={values?.region}
            {...commonDropdownProps}
          />
        </StyledFlex>
        <StyledFlex>
          <CustomDropdown
            placeholder="Select Highest Qualification"
            fieldName={"highestQualification"}
            label={"Highest Qualification "}
            value={values?.highestQualification}
            {...commonDropdownProps}
          />
          <CustomDropdown
            placeholder="Select Experience"
            fieldName={"experience"}
            label={"Experience"}
            value={values?.experience}
            {...commonDropdownProps}
          />
        </StyledFlex>
        <StyledFlex>
          <CustomDropdown
            placeholder="Select Province"
            fieldName={"province"}
            label={"Province"}
            value={values?.province}
            {...commonDropdownProps}
          />
          <CustomDropdown
            placeholder="Select Commune"
            fieldName={"commune"}
            label={"Commune"}
            value={values?.commune}
            {...commonDropdownProps}
          />
        </StyledFlex>
      </FormLayout>
    </MainLayout>
  );
};

export default SchoolUserTeacherForm;
