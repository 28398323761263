import React, { useState, Suspense, lazy } from "react";
import { CustomTable } from "components/CustomTable";
import CustomModal from "components/CustomModal";
import RenderTableActionsButton from "components/RenderTableActionsButton";
import {
  REDUX_KEYS_ACTIONS,
  STATIC_VALUES,
  STRING_CONSTANTS,
} from "constants/common";
import "./index.css";
import { useSelector } from "react-redux";

const MainLayout = lazy(() => import("layouts/MainLayout"));
const RenderSchoolTypeMasterFilters = lazy(() =>
  import("./RenderSchoolTypeMasterFilters")
);

const SchoolTypeMasterComp = () => {
  const { schoolTypeMasterReduxData } = useSelector(
    (state) => state[REDUX_KEYS_ACTIONS.SCHOOL_TYPE_MASTER.FEATURE_KEY]
  );
  const [isFilter, setIsFilter] = useState(false);
  const [switchChecked, setSwitchChecked] = useState(false);
  const defaultModalProps = { isOpen: false, data: null };
  const [modal, setModal] = useState(defaultModalProps);

  const columns = [
    {
      field: "id",
      header: STATIC_VALUES.COLUMNS.COMMON.ID,
    },
    {
      field: "schools",
      header: STATIC_VALUES.COLUMNS.COMMON.SCHOOLS,
      enableSort: true,
    },
    {
      field: "category",
      header: STATIC_VALUES.COLUMNS.COMMON.CATEGORY,
      enableSort: true,
    },
    {
      field: "status",
      header: STATIC_VALUES.COLUMNS.COMMON.STATUS,
      enableSort: true,
    },
    {
      field: "schoolType",
      header: STATIC_VALUES.COLUMNS.COMMON.SCHOOL_TYPE,
      enableSort: true,
    },
    {
      field: "educationType",
      header: STATIC_VALUES.COLUMNS.COMMON.EDUCATION_TYPE,
      enableSort: true,
    },
    {
      field: "createdDate",
      header: STATIC_VALUES.COLUMNS.COMMON.CREATED_DATE,
      enableSort: true,
    },
    {
      field: "updatedDate",
      header: STATIC_VALUES.COLUMNS.COMMON.UPDATED_DATE,
      enableSort: true,
    },
    {
      field: "action",
      header: STATIC_VALUES.COLUMNS.COMMON.ACTIONS,
      shouldRenderElement: true,
      renderElement: () => {
        return (
          <RenderTableActionsButton
            setModal={setModal}
            switchChecked={switchChecked}
            handleOnSwitch={handleOnSwitch}
            handleEditClick={() => setModal({ data, isOpen: true })}
            handleDeleteClick={() => setModal({ data, isOpen: true })}
            showViewIcon={false}
            showEditIcon={false}
            showDeleteIcon={false}
          />
        );
      },
    },
  ];

  const data = [
    {
      id: "1",
      schools: "John Brown",
      category: "Category",
      boardName: "CBSE",
      status: "Active",
      schoolType: "Primary",
      educationType: "formal",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
    },
    {
      id: "2",
      schools: "Jim Green",
      category: "Category",
      boardName: "SCC",
      status: "Active",
      schoolType: "Primary",
      educationType: "formal",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
    },
    {
      id: "3",
      schools: "Joe Black",
      category: "Category",
      boardName: "CBSE",
      status: "Active",
      schoolType: "Primary",
      educationType: "formal",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
    },
    {
      id: "4",
      schools: "Jim Green",
      category: "Category",
      boardName: "SCC",
      status: "Inactive",
      schoolType: "Primary",
      educationType: "formal",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
    },
    {
      id: "5",
      schools: "Joe Black",
      category: "Category",
      boardName: "CBSE",
      status: "Inactive",
      schoolType: "Primary",
      educationType: "formal",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
    },
    {
      id: "6",
      schools: "Jim Green",
      category: "Category",
      boardName: "SCC",
      status: "Active",
      schoolType: "Primary",
      educationType: "formal",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
    },
    {
      id: "7",
      schools: "Joe Black",
      category: "Category",
      boardName: "CBSE",
      status: "Inactive",
      schoolType: "Primary",
      educationType: "formal",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
    },
    {
      id: "8",
      schools: "Jim Green",
      category: "Category",
      boardName: "SCC",
      status: "Active",
      schoolType: "Primary",
      educationType: "formal",
      createdDate: "12/09/2024",
      updatedDate: "18/10/2024",
    },
  ];

  const onChangeData = () => {};

  const multiSelectOptions = [
    { label: "Option 1", value: "option1" },
    { label: "Option 2", value: "option2" },
    { label: "Option 3", value: "option3" },
  ];

  const handleIsFilter = () => {
    setIsFilter(!isFilter);
  };

  const handleOnSwitch = (e, key) => {
    setSwitchChecked(() => e?.target?.checked);
  };

  return (
    <Suspense>
      <MainLayout>
        {modal?.isOpen ? (
          <CustomModal
            modal={modal?.isOpen}
            setModal={() => setModal(defaultModalProps)}
            modalTitle={"Delete Modal"}
            modalInnerContent={STRING_CONSTANTS.COMMON.RECORD_DELETE_STRING}
            footerBtnText={"Yes"}
          />
        ) : null}
        <CustomTable
          showAddButton={false}
          isFilter={isFilter}
          tableHeader={"School Type Master"}
          handleIsFilter={handleIsFilter}
          filterOptions={
            <Suspense>
              <RenderSchoolTypeMasterFilters
                showDateFilter={true}
                showMultiSelectFilter={true}
                multiSelectOptions={multiSelectOptions}
              />
            </Suspense>
          }
          data={data}
          columns={columns}
          count={10}
          width={"100"}
          noDataFoundMessage={
            STRING_CONSTANTS.SCHOOL_TYPE_MASTER
              .NO_DATA_FOR_SCHOOL_TYPE_MASTER_FOUND
          }
          class={"table_class"}
          onChangeData={onChangeData}
          btnMarginTop={false}
        />
      </MainLayout>
    </Suspense>
  );
};

export default SchoolTypeMasterComp;
