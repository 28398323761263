import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { MENULIST } from "constants/sidenavMenuItemList";
import { IconEdulacLogo, IconEdulacSymbol } from "assets/images/SVGs";
import CustomSearchInput from "components/CustomSearchInput";
import { STRING_CONSTANTS } from "constants/common";
import "./index.css";

const SideNav = ({ isCollapsed, setIsCollapsed }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const currentRoute = location.pathname;

  const filteredMenuList = searchTerm
    ? MENULIST?.filter((menuItem) =>
        menuItem?.text?.toLowerCase().includes(searchTerm?.toLowerCase())
      )
    : MENULIST;

  const handleItemClick = (menuItem) => {
    setSelectedItem(menuItem);
    setSearchTerm(menuItem?.text);
    setIsCollapsed(true);
    navigate(menuItem?.path);
  };

  return (
    <div className={`sidebar-main-wrapper ${isCollapsed ? "collapsed" : ""}`}>
      <div
        className="side-nav-logo"
        onClick={() => setIsCollapsed((prev) => !prev)}
      >
        <p>{isCollapsed ? <IconEdulacSymbol /> : <IconEdulacLogo />}</p>
      </div>
      <p className="search-text-field">What would you like to do:</p>
      <CustomSearchInput
        value={searchTerm}
        onChange={(term) => setSearchTerm(term)}
        debounce={500}
        placeholder="Quick Search"
        showIcon={true}
        cancelHandler={() => setSearchTerm("")}
      />
      <div className="sidebar-list">
        {filteredMenuList?.length > 0 ? (
          filteredMenuList?.map((menuItem, index) => {
            const isActive =
              currentRoute === menuItem?.path || selectedItem === menuItem;
            return (
              <li
                onClick={() => handleItemClick(menuItem)}
                key={`side_nav_${index}`}
                className={`sidebar-item ${isActive ? "active" : ""}`}
              >
                <div
                  className={`sidenav-menu-div ${
                    isCollapsed ? "collapsed" : ""
                  }`}
                >
                  <div
                    className={`icon-wrapper ${isCollapsed ? "collapsed" : ""}`}
                  >
                    {menuItem?.icon}
                  </div>
                  <span
                    className={`sidenav-menu-item-span ${
                      isCollapsed ? "collapsed" : ""
                    }`}
                  >
                    {menuItem?.text}
                  </span>
                </div>
              </li>
            );
          })
        ) : (
          <p className="side-nav-no-results">
            {STRING_CONSTANTS.COMMON.SIDE_BAR_ITEMS_NOT_FOUND}
          </p>
        )}
      </div>
    </div>
  );
};

export default SideNav;
