import _ from "lodash";

export const is_required = (value, isRequired, error) => {
  if (Array.isArray(value)) {
    if (value.length === 0 && isRequired) return error;
  } else if (!value && isRequired) return error;
  return "";
};

export const is_object = (value) => {
  return typeof value === "object" && value !== null;
};

export const get_prop_values = (stateSchema, prop) => {
  return Object.keys(stateSchema).reduce((accumulator, curr) => {
    accumulator[curr] = !prop ? false : stateSchema?.[curr]?.[prop];

    return accumulator;
  }, {});
};

export const is_micro_field_required = (
  value,
  isRequired,
  error,
  type = ""
) => {
  if (Array.isArray(value)) {
    if (value.length === 0 && isRequired) return error;
  } else if (type === "NUMBER" || type === "PERCENTAGE_OBTAINED") {
    if (!_.isNumber(value) && !value) {
      return error;
    }
  } else if (!value && isRequired) return error;
  return "";
};

export const getLanguages = (list) => {
  const languages = [];

  list?.forEach(({ languageCode, name }) => {
    languages?.push({ languageCode, name });
  });

  const filteredLanguages = languages?.filter(
    (lang) => lang?.languageCode && lang?.name
  );
  return filteredLanguages;
};
