import React, { useState } from "react";
import { Menu, Dropdown, Popover, Badge } from "antd";
import {
  IconBell,
  IconHamburger,
  IconLogout,
  IconHeaderProfile,
  IconSetting,
  IconRightArrow,
} from "assets/images/SVGs";
import { useNavigate } from "react-router-dom";
import CustomModal from "components/CustomModal";
import { STRING_CONSTANTS, ROUTE_CONSTANTS } from "constants/common";
import NotificationList from "./NotificationList";
import { STATIC_DATA } from "constants/staticData";
import "./index.css";

const NavBar = ({ toggleSidebar, isCollapsed }) => {
  const navigate = useNavigate();
  const defaultModalProps = { isOpen: false, data: null };
  const [modal, setModal] = useState(defaultModalProps);
  const [notifications] = useState(
    STATIC_DATA.NOTIFICATIONS.NOTIFICATIONS_LIST
  );

  const logoutHandler = () => {
    setModal({ isOpen: true, data: null });
  };

  const onHandleConfirm = () => {
    navigate(ROUTE_CONSTANTS.HOME);
    setModal({ isOpen: false, data: null });
  };

  const renderNotificationLists = () => {
    return (
      <NotificationList
        {...{
          notifications,
        }}
      />
    );
  };

  const menu = (
    <Menu>
      <Menu.Item key={"menu_" + 1}>
        <span>My Account</span>
      </Menu.Item>
      <Menu.Item key={"menu_" + 2}>
        <span>Change Password</span>
      </Menu.Item>
      <Menu.Item key={"menu_" + 3}>
        <span>Sync-Activity</span>
      </Menu.Item>
      <Menu.Item key={"menu_" + 4} onClick={logoutHandler}>
        <span className={"logout_text"}>Sign Out</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={"navbar_wrapper"}>
      <div onClick={toggleSidebar}>
        {isCollapsed ? (
          <IconRightArrow width={15} height={15} />
        ) : (
          <IconHamburger width={15} height={15} />
        )}
      </div>
      <div className={"navbar_right_side"}>
        <p className={"navbar_profile_name"}>Hi, Riddhi</p>
        <Popover
          content={renderNotificationLists}
          trigger="click"
          className="notification_popover_container"
          placement="bottomRight"
        >
          <Badge
            count={notifications?.length}
            offset={[0, 0]}
            classNames={"badge_aligment"}
          >
            <IconBell width={20} height={20} />
          </Badge>
        </Popover>
        <IconSetting width={20} height={20} />

        <Dropdown overlay={menu} trigger={["click"]} placement="bottomRight">
          <div onClick={(e) => e?.preventDefault()}>
            <IconHeaderProfile
              width={20}
              height={20}
              position="relative"
              top="2px"
            />
          </div>
        </Dropdown>

        <IconLogout width={20} height={20} onClick={logoutHandler} />
        {modal?.isOpen ? (
          <CustomModal
            modal={modal?.isOpen}
            setModal={() => setModal(defaultModalProps)}
            modalTitle={"Sign Out"}
            modalInnerContent={
              STRING_CONSTANTS.COMMON.SIGN_OUT_TITLE_CONFIRM_MESSAGE
            }
            footerBtnText={"Yes"}
            handleOnConfirm={onHandleConfirm}
          />
        ) : null}
      </div>
    </div>
  );
};

export default NavBar;
