import React, { useState, Suspense, lazy, useEffect } from "react";
import { CustomTable } from "components/CustomTable";
import CustomModal from "components/CustomModal";
import RenderTableActionsButton from "components/RenderTableActionsButton";
import {
  STRING_CONSTANTS,
  ROUTE_CONSTANTS,
  STATIC_VALUES,
  REDUX_KEYS_ACTIONS,
} from "constants/common";
import { useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import CustomDatePicker from "components/CustomDatePicker";
import { useForm } from "hooks/useForm";
import { useSelector } from "react-redux";
import "./index.css";

const MainLayout = lazy(() => import("layouts/MainLayout"));
const RenderSchoolMasterFilters = lazy(() =>
  import("./RenderSchoolMasterFilters")
);

const SchoolMaster = () => {
  const { schoolManagementReduxData } = useSelector(
    (state) => state[REDUX_KEYS_ACTIONS.SCHOOL_MANAGEMENT.FEATURE_KEY]
  );
  const location = useLocation();
  const [isPopularSchools, setIsPopularSchools] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [switchChecked, setSwitchChecked] = useState(false);
  const [isCheckBoxChecked] = useState(false);
  const [actionsIds, setActionIds] = useState([]);
  const defaultModalProps = { isOpen: false, data: null };
  const [modal, setModal] = useState(defaultModalProps);
  const [editModal, setEditModal] = useState(defaultModalProps);
  const navigate = useNavigate();

  const initialState = {
    duration: {
      value: "",
      error: "",
    },
  };

  const handleAddDurationForSchools = (params, error) => {};

  const { errors, values, dirty, setValues, handleOnSubmit } = useForm(
    initialState,
    handleAddDurationForSchools
  );

  useEffect(() => {
    setIsPopularSchools(location?.pathname === ROUTE_CONSTANTS.POPULAR_SCHOOLS);
  }, [location?.pathname]);

  const columns = [
    ...(isPopularSchools
      ? []
      : [
          {
            field: "select",
            header: STATIC_VALUES.COLUMNS.COMMON.SELECT,
            shouldRenderElement: true,
            actionInField: true,
            actionType: "checkbox",
            customHeaderWidth: "2",
            renderElement: (ele, index, data) => {
              const isChecked = actionsIds?.includes(data?.id);
              return (
                <input
                  id={index}
                  name={`checkbox-${index}`}
                  type="checkbox"
                  checked={isChecked}
                  onChange={() => onHandleCheckBoxClickEvent(data)}
                />
              );
            },
          },
        ]),
    {
      field: "id",
      header: STATIC_VALUES.COLUMNS.COMMON.ID,
    },
    {
      field: "schoolName",
      header: STATIC_VALUES.COLUMNS.COMMON.SCHOOL_NAME,
      enableSort: true,
    },
    {
      field: "schoolCycle",
      header: STATIC_VALUES.COLUMNS.SCHOOL_MANAGEMENT_MASTER.SCHOOL_CYCLE,
      enableSort: true,
    },
    {
      field: "schoolStatus",
      header: STATIC_VALUES.COLUMNS.COMMON.SCHOOl_STATUS,
      enableSort: true,
    },
    {
      field: "schoolType",
      header: STATIC_VALUES.COLUMNS.COMMON.SCHOOL_TYPE,
      enableSort: true,
    },
    {
      field: "schoolEducationType",
      header:
        STATIC_VALUES.COLUMNS.SCHOOL_MANAGEMENT_MASTER.SCHOOL_EDUCATION_TYPE,
      enableSort: true,
    },
    {
      field: "partnerShipStatus",
      header: STATIC_VALUES.COLUMNS.SCHOOL_MANAGEMENT_MASTER.PARTNERSHIP_STATUS,
      enableSort: true,
    },
    {
      field: "administrativeStatus",
      header:
        STATIC_VALUES.COLUMNS.SCHOOL_MANAGEMENT_MASTER.ADMINISTRATIVE_STATUS,
      enableSort: true,
    },
    {
      field: "region",
      header: STATIC_VALUES.COLUMNS.COMMON.REGION,
      enableSort: true,
    },
    {
      field: "province",
      header: STATIC_VALUES.COLUMNS.COMMON.PROVINCE,
      enableSort: true,
    },
    {
      field: "commune",
      header: STATIC_VALUES.COLUMNS.COMMON.REGION,
      enableSort: true,
    },
    {
      field: "contactInfo",
      header: STATIC_VALUES.COLUMNS.SCHOOL_MANAGEMENT_MASTER.CONTACT_INFO,
      enableSort: true,
    },
    {
      field: "registeredAt",
      header: STATIC_VALUES.COLUMNS.COMMON.REGISTERED_AT,
      enableSort: true,
    },
    {
      field: "action",
      header: STATIC_VALUES.COLUMNS.COMMON.ACTIONS,
      shouldRenderElement: true,
      renderElement: (value, index, data) => {
        return (
          <RenderTableActionsButton
            setModal={setModal}
            switchChecked={switchChecked}
            handleOnSwitch={handleOnSwitch}
            handleDeleteClick={() => setModal({ data, isOpen: true })}
            showViewIcon={true}
            showDeleteIcon={true}
            showEditIcon={isPopularSchools}
            handleViewRoute={ROUTE_CONSTANTS.VIEW_SCHOOL_MASTER}
            showEditModal={true}
            handleEditClick={() => setEditModal({ isOpen: true, data: data })}
          />
        );
      },
    },
  ];

  const data = [
    {
      id: "1",
      schoolName: "Saint Marys",
      schoolCycle: "Pre-primary",
      schoolStatus: "Enabled",
      schoolType: "Classical",
      schoolEducationType: "General",
      partnerShipStatus: "Conventional",
      administrativeStatus: "Recognized",
      region: "Location",
      province: "Province",
      commune: "Commune",
      contactInfo: "9898760987",
      registeredAt: "04-oct-2024",
    },
    {
      id: "2",
      schoolName: "Saint Annas",
      schoolCycle: "Secondary",
      schoolStatus: "Disabled",
      schoolType: "Scientific",
      schoolEducationType: "Technical",
      partnerShipStatus: "Non-Conventional",
      administrativeStatus: "Non-recognized",
      region: "Location",
      province: "Province",
      commune: "Commune",
      contactInfo: "9898760987",
      registeredAt: "04-oct-2024",
    },
    {
      id: "3",
      schoolName: "Saint Camry",
      schoolCycle: "Post-secondary",
      schoolStatus: "Enabled",
      schoolType: "Multipurpose",
      schoolEducationType: "Medical",
      partnerShipStatus: "Conventional",
      administrativeStatus: "Recognized",
      region: "Location",
      province: "Province",
      commune: "Commune",
      contactInfo: "9898760987",
      registeredAt: "04-oct-2024",
    },
    {
      id: "4",
      schoolName: "VidhyaNiketan",
      schoolCycle: "Pre-primary",
      schoolStatus: "Enabled",
      schoolType: "Classical",
      schoolEducationType: "General",
      partnerShipStatus: "Conventional",
      administrativeStatus: "Recognized",
      region: "Location",
      province: "Province",
      commune: "Commune",
      contactInfo: "9898760987",
      registeredAt: "04-oct-2024",
    },
    {
      id: "5",
      schoolName: "Saint Marys",
      schoolCycle: "Pre-primary",
      schoolStatus: "Enabled",
      schoolType: "Classical",
      schoolEducationType: "General",
      partnerShipStatus: "Conventional",
      administrativeStatus: "Recognized",
      region: "Location",
      province: "Province",
      commune: "Commune",
      contactInfo: "9898760987",
      registeredAt: "04-oct-2024",
    },
    {
      id: "6",
      schoolName: "Saint Marys",
      schoolCycle: "Pre-primary",
      schoolStatus: "Enabled",
      schoolType: "Classical",
      schoolEducationType: "General",
      partnerShipStatus: "Conventional",
      administrativeStatus: "Recognized",
      region: "Location",
      province: "Province",
      commune: "Commune",
      contactInfo: "9898760987",
      registeredAt: "04-oct-2024",
    },
    {
      id: "7",
      schoolName: "Saint Marys",
      schoolCycle: "Pre-primary",
      schoolStatus: "Enabled",
      schoolType: "Classical",
      schoolEducationType: "General",
      partnerShipStatus: "Conventional",
      administrativeStatus: "Recognized",
      region: "Location",
      province: "Province",
      commune: "Commune",
      contactInfo: "9898760987",
      registeredAt: "04-oct-2024",
    },
    {
      id: "8",
      schoolName: "Saint Marys",
      schoolCycle: "Pre-primary",
      schoolStatus: "Enabled",
      schoolType: "Classical",
      schoolEducationType: "General",
      partnerShipStatus: "Conventional",
      administrativeStatus: "Recognized",
      region: "Location",
      province: "Province",
      commune: "Commune",
      contactInfo: "9898760987",
      registeredAt: "04-oct-2024",
    },
  ];

  const onChangeData = () => {};

  const multiSelectOptions = [
    { label: "Option 1", value: "option1" },
    { label: "Option 2", value: "option2" },
    { label: "Option 3", value: "option3" },
  ];

  const handleIsFilter = () => {
    setIsFilter(!isFilter);
  };

  const handleOnSwitch = (e, key) => {
    setSwitchChecked(() => e?.target?.checked);
  };

  const handleAddRoute = (params, error) => {
    navigate(ROUTE_CONSTANTS.ADD_SCHOOL_MASTER);
  };

  const onHandleCheckBoxClickEvent = (data) => {
    const clonedArray = _.cloneDeep(actionsIds);
    if (!clonedArray?.includes(data?.id)) {
      clonedArray.push(data?.id);
    } else {
      let i = clonedArray?.findIndex((x) => x === data?.id);
      if (i >= 0 && clonedArray?.length > 0) {
        clonedArray?.splice(i, 1);
      }
    }
    setActionIds(clonedArray);
  };

  const handleHeaderCheckBox = () => {
    setActionIds(actionsIds?.length ? [] : data?.map((d) => d?.id));
  };

  const onDateRangeChange = (val) => {
    setValues((prevValues) => ({
      ...prevValues,
      duration: val,
    }));
  };

  const renderDurationContent = () => {
    return (
      <CustomDatePicker
        fieldName={"duration"}
        title={"Duration"}
        hasIcon={true}
        disableAutoFocus={true}
        isRangeDatePicker={true}
        className={"school-master-duration-date"}
        data={values?.duration}
        hasError={errors?.duration && dirty?.duration}
        errorMessage={errors?.duration}
        onChangeHandler={onDateRangeChange}
      />
    );
  };

  return (
    <Suspense>
      <MainLayout>
        {modal?.isOpen ? (
          <CustomModal
            modal={modal?.isOpen}
            setModal={() => setModal(defaultModalProps)}
            modalTitle={"Delete Modal"}
            modalInnerContent={
              isPopularSchools
                ? STRING_CONSTANTS.COMMON.DISABLE_RECORD_TITLE
                : STRING_CONSTANTS.COMMON.RECORD_DELETE_STRING
            }
            footerBtnText={"Yes"}
          />
        ) : null}
        <CustomTable
          showAddButton={true}
          handleAddRoute={handleAddRoute}
          isFilter={isFilter}
          addBtnText={"Add"}
          showExportButton={true}
          tableHeader={
            isPopularSchools
              ? STRING_CONSTANTS.POPULAR_SCHOOLS.HEADER_TITLE_POPULAR_SCHOOLS
              : STRING_CONSTANTS.SCHOOL_MASTER.HEADER_TITLE_SCHOOL_LISTING
          }
          handleIsFilter={handleIsFilter}
          showPopular={!isPopularSchools}
          showDuration={true}
          filterOptions={
            <Suspense>
              <RenderSchoolMasterFilters
                showDateFilter={true}
                showMultiSelectFilter={true}
                multiSelectOptions={multiSelectOptions}
              />
            </Suspense>
          }
          data={data}
          columns={columns}
          count={10}
          width={"100"}
          noDataFoundMessage={
            STRING_CONSTANTS.SCHOOL_MASTER.NO_DATA_FOR_SCHOOL_MASTER_FOUND
          }
          class={"table_class"}
          onChangeData={onChangeData}
          btnMarginTop={false}
          childCheckBoxChecked={isCheckBoxChecked}
          setChildCheckBoxChecked={handleHeaderCheckBox}
        />
        {editModal?.isOpen ? (
          <CustomModal
            modal={editModal?.isOpen}
            setModal={() => setEditModal(defaultModalProps)}
            modalTitle={"Set Duration For School"}
            modalInnerContent={renderDurationContent()}
            footerBtnText={"Update"}
            handleOnConfirm={handleOnSubmit}
          />
        ) : null}
        <CustomModal />
      </MainLayout>
    </Suspense>
  );
};

export default SchoolMaster;
