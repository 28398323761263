import React, { Suspense, lazy, useState } from "react";
import CustomModal from "components/CustomModal";
import RenderTableActionsButton from "components/RenderTableActionsButton";
import {
  REDUX_KEYS_ACTIONS,
  ROUTE_CONSTANTS,
  STATIC_VALUES,
  STRING_CONSTANTS,
} from "constants/common";
import { CustomTable } from "components/CustomTable";
import { useSelector } from "react-redux";
import "./index.css";

const MainLayout = lazy(() => import("layouts/MainLayout"));
const RenderMinisterUserManagementFilters = lazy(() =>
  import("./RenderMinisterUserManagementFilters")
);

const MinisterUserManagement = () => {
  const { ministerUserManagementReduxData } = useSelector(
    (state) => state[REDUX_KEYS_ACTIONS.MINISTER_USER_MANAGEMENT.FEATURE_KEY]
  );
  const [isFilter, setIsFilter] = useState(false);
  const [switchChecked, setSwitchChecked] = useState(false);
  const defaultModalProps = { isOpen: false, data: null };
  const [modal, setModal] = useState(defaultModalProps);

  const columns = [
    {
      field: "ministerId",
      header: STATIC_VALUES.COLUMNS.COMMON.MINISTER_ID,
    },
    {
      field: "ministerName",
      header: STATIC_VALUES.COLUMNS.MINISTER_USER_MANAGEMENT.MINISTER_NAME,
      enableSort: true,
    },
    {
      field: "ministerCycle",
      header: STATIC_VALUES.COLUMNS.MINISTER_USER_MANAGEMENT.MINISTER_CYCLE,
      enableSort: true,
    },
    {
      field: "gender",
      header: STATIC_VALUES.COLUMNS.COMMON.GENDER,
      enableSort: true,
    },
    {
      field: "emailId",
      header: STATIC_VALUES.COLUMNS.COMMON.EMAIL_ID,
      enableSort: true,
    },
    {
      field: "region",
      header: STATIC_VALUES.COLUMNS.COMMON.REGION,
      enableSort: true,
    },
    {
      field: "province",
      header: STATIC_VALUES.COLUMNS.COMMON.PROVINCE,
      enableSort: true,
    },
    {
      field: "commune",
      header: STATIC_VALUES.COLUMNS.COMMON.COMMUNE,
      enableSort: true,
    },
    {
      field: "contactInformation",
      header: STATIC_VALUES.COLUMNS.COMMON.CONATACT_INFORMATION,
      enableSort: true,
    },
    {
      field: "registeredAt",
      header: STATIC_VALUES.COLUMNS.COMMON.REGISTERED_AT,
      enableSort: true,
    },
    {
      field: "action",
      header: STATIC_VALUES.COLUMNS.COMMON.ACTIONS,
      shouldRenderElement: true,
      renderElement: () => {
        return (
          <RenderTableActionsButton
            setModal={setModal}
            switchChecked={switchChecked}
            handleOnSwitch={handleOnSwitch}
            handleDeleteClick={() => setModal({ data, isOpen: true })}
            showViewIcon={true}
            handleViewRoute={ROUTE_CONSTANTS.VIEW_MINISTER_USER_MANAGEMENT}
          />
        );
      },
    },
  ];

  const data = [
    {
      ministerId: "1",
      ministerName: "Dharmendra",
      ministerCycle: "Primary",
      gender: "Male",
      emailId: "demo@gmail.com",
      region: "Location",
      province: "Province",
      commune: "Commune",
      contactInformation: "9898760987",
      registeredAt: "04-oct-2024",
    },
    {
      ministerId: "2",
      ministerName: "Keval",
      ministerCycle: "Primary",
      gender: "Male",
      emailId: "demo@gmail.com",
      region: "Location",
      province: "Province",
      commune: "Commune",
      contactInformation: "9898760987",
      registeredAt: "04-oct-2024",
    },
    {
      ministerId: "3",
      ministerName: "Piyush",
      ministerCycle: "Primary",
      gender: "Male",
      emailId: "demo@gmail.com",
      region: "Location",
      province: "Province",
      commune: "Commune",
      contactInformation: "9898760987",
      registeredAt: "04-oct-2024",
    },
    {
      ministerId: "4",
      ministerName: "Maria",
      ministerCycle: "Secondary",
      gender: "Male",
      emailId: "demo@gmail.com",
      region: "Location",
      province: "Province",
      commune: "Commune",
      contactInformation: "9898760987",
      registeredAt: "04-oct-2024",
    },
    {
      ministerId: "5",
      ministerName: "Aakash",
      ministerCycle: "Secondary",
      gender: "Female",
      emailId: "demo@gmail.com",
      region: "Location",
      province: "Province",
      commune: "Commune",
      contactInformation: "9898760987",
      registeredAt: "04-oct-2024",
    },
    {
      ministerId: "6",
      ministerName: "Mehul",
      ministerCycle: "Secondary",
      gender: "Female",
      emailId: "demo@gmail.com",
      region: "Location",
      province: "Province",
      commune: "Commune",
      contactInformation: "9898760987",
      registeredAt: "04-oct-2024",
    },
    {
      ministerId: "7",
      ministerName: "Seema",
      ministerCycle: "Secondary",
      gender: "Female",
      emailId: "demo@gmail.com",
      region: "Location",
      province: "Province",
      commune: "Commune",
      contactInformation: "9898760987",
      registeredAt: "04-oct-2024",
    },
    {
      ministerId: "8",
      ministerName: "Dharmendra",
      ministerCycle: "Secondary",
      gender: "Female",
      emailId: "demo@gmail.com",
      region: "Location",
      province: "Province",
      commune: "Commune",
      contactInformation: "9898760987",
      registeredAt: "04-oct-2024",
    },
  ];

  const onChangeData = () => {};

  const multiSelectOptions = [
    { label: "Option 1", value: "option1" },
    { label: "Option 2", value: "option2" },
    { label: "Option 3", value: "option3" },
  ];

  const handleIsFilter = () => {
    setIsFilter(!isFilter);
  };

  const handleOnSwitch = (e, key) => {
    setSwitchChecked(() => e?.target?.checked);
  };

  return (
    <Suspense>
      <MainLayout>
        {modal?.isOpen ? (
          <CustomModal
            modal={modal?.isOpen}
            setModal={() => setModal(defaultModalProps)}
            modalTitle={"Delete Modal"}
            modalInnerContent={STRING_CONSTANTS.COMMON.RECORD_DELETE_STRING}
            footerBtnText={"Yes"}
          />
        ) : null}
        <CustomTable
          showExportButton={true}
          isFilter={isFilter}
          tableHeader={
            STRING_CONSTANTS.MINISTER_USER_MANAGEMENT
              .HEADER_TITLE_MINISTER_USER_MANAGEMENT
          }
          handleIsFilter={handleIsFilter}
          filterOptions={
            <Suspense>
              <RenderMinisterUserManagementFilters
                showDateFilter={true}
                showMultiSelectFilter={true}
                multiSelectOptions={multiSelectOptions}
              />
            </Suspense>
          }
          data={data}
          columns={columns}
          count={10}
          width={"100"}
          noDataFoundMessage={
            STRING_CONSTANTS.MINISTER_USER_MANAGEMENT
              .NO_DATA_FOR_MINISTER_USER_FOUND
          }
          class={"table_class"}
          onChangeData={onChangeData}
          btnMarginTop={false}
        />
      </MainLayout>
    </Suspense>
  );
};

export default MinisterUserManagement;
