import React from "react";
import { Skeleton } from "antd/lib";

export const CustomSkeleton = (props) => {
  const {
    loading = true,
    title = true,
    avatar = false,
    paragraph = { rows: 3 },
    active = true,
    size = "large",
    shape = "square",
  } = props;
  return (
    <Skeleton
      loading={loading}
      avatar={avatar ? { shape, size } : false}
      title={title}
      paragraph={paragraph}
      active={active}
    />
  );
};
