import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
} from "@reduxjs/toolkit";
import { REDUX_KEYS_ACTIONS } from "constants/common";

export const ministerUserManagementAdapter = createEntityAdapter();

export const fetchMinisterUserList = createAsyncThunk(
  REDUX_KEYS_ACTIONS.MINISTER_USER_MANAGEMENT.FETCH_MINISTER_USER_LIST,
  async () => {
    const response = await fetch("");
    return response?.json();
  }
);

export const initialMinisteUserManagementState = {
  ministerUserManagementReduxData: {
    loadingStatus: "not loaded",
    error: null,
    isLoading: false,
  },
};

export const ministerUserManagementSlice = createSlice({
  name: REDUX_KEYS_ACTIONS.MINISTER_USER_MANAGEMENT.FEATURE_KEY,
  initialState: initialMinisteUserManagementState,
  reducers: {
    add: ministerUserManagementAdapter.addOne,
    remove: ministerUserManagementAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMinisterUserList.pending, (state) => {
      state.ministerUserManagementReduxData.isLoading = true;
      state.ministerUserManagementReduxData.loadingStatus = "loading";
    });
  },
});

export const ministerUserManagementReducer =
  ministerUserManagementSlice.reducer;

export const ministerUserManagementActions =
  ministerUserManagementSlice.actions;

export const getMinisterUserManagementState = (rootState) =>
  rootState[REDUX_KEYS_ACTIONS.MINISTER_USER_MANAGEMENT.FEATURE_KEY];
