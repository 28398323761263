import React from "react";
import Button from "components/CustomButton";
import { Helmet } from "react-helmet";
import "./tableHeaderStyles.css";
import { STRING_CONSTANTS } from "constants/common";

const TableHeader = ({
  tableHeader,
  showAddButton,
  addBtnText,
  handleAddRoute,
  btnMarginTop,
}) => {
  return (
    <div className="table_header_wrapper">
      <Helmet>
        <title>{`${STRING_CONSTANTS.COMMON.EDULAC_TITLE} - ${tableHeader}`}</title>
      </Helmet>
      <div className="table_header_inner_wrapper">
        <p className="table_header_para">{tableHeader}</p>
        {showAddButton ? (
          addBtnText ? (
            <Button
              btnText={addBtnText}
              onClickHandler={handleAddRoute}
              btnMarginTop={btnMarginTop}
              width={100}
            />
          ) : null
        ) : null}
      </div>
    </div>
  );
};

export default TableHeader;
