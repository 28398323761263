import React from "react";
import AuthLayout from "layouts/AuthLayout";
import { Container } from "styled-bootstrap-grid";
import { useNavigate } from "react-router-dom";
import { useForm } from "hooks/useForm";
import CustomInput from "components/CustomInput";
import CustomButton from "components/CustomButton";
import AuthComponentsHeader from "components/AuthComponentsHeader";
import { ROUTE_CONSTANTS, STRING_CONSTANTS } from "constants/common";

const CreateNewPassword = () => {
  const navigate = useNavigate();

  const initialState = {
    email: {
      value: "",
      error: "",
    },
    password: {
      value: "",
      error: "",
    },
  };

  const handleCreateNewPassword = (params, error) => {
    navigate(ROUTE_CONSTANTS.HOME);
  };

  const { errors, handleOnSubmit, handleOnChange } = useForm(
    initialState,
    handleCreateNewPassword
  );

  return (
    <AuthLayout>
      <Container>
        <div className="auth_wrapper">
          <div className="auth_inner_wrapper">
            <AuthComponentsHeader authComponentHeader={"Create Password"} />
            <CustomInput
              name="newPassword"
              type="email"
              placeholder="New Password"
              onChangeHandler={handleOnChange}
              label="New Password"
            />
            <CustomInput
              name="confirmPassword"
              type="password"
              placeholder="Confirm Password"
              onChangeHandler={handleOnChange}
              label="Confirm Password"
            />
            <CustomButton
              btnText={STRING_CONSTANTS.COMMON.BUTTON.SAVE_TITLE}
              onClickHandler={handleOnSubmit}
              btnMarginTop
              width={100}
            />
          </div>
        </div>
      </Container>
    </AuthLayout>
  );
};

export default CreateNewPassword;
